import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { localApi } from "utils/imgApi";
import { useHistory, useLocation } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { Card } from "@material-ui/core";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Tooltip from "@material-ui/core/Tooltip";
import Close from "@material-ui/icons/Close";
import { IoLogoWhatsapp } from "react-icons/io";
import { ImMobile } from "react-icons/im";
import { MdEmail } from "react-icons/md";
import { TiPhoneOutline } from "react-icons/ti";
import styles from "assets/jss/material-dashboard-pro-react/views/viewCartPrintStyle";
import logo from "assets/img/apple-admin-icon.png";

const useStyles = makeStyles(styles);

export default function LabourClientBillViewPrintWithoutSize() {
  const [viewCart, setViewCart] = React.useState([]);
  const [adminAddress, setAdminAddress] = React.useState([]);
  const [regAddress1, setRegAddress1] = React.useState([]);
  const [regAddress2, setRegAddress2] = React.useState([]);

  const classTable = useStyles();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  const { search } = useLocation();
  const client = new URLSearchParams(search).get("client");
  const ad = new URLSearchParams(search).get("ad");

  // const { state } = useLocation();
  // console.log(state);

  async function getProd() {
    const get = localApi + `/adminAddress/list/byAdminId/${userObj.admin.id}`;
    const results = await fetch(get);
    const res = await results.json();
    setAdminAddress(res && res.filter((a) => a.addressType === "GSTIN ADDRESS"));
    // console.log(res);
  }

  async function getClient() {
    const api =
      localApi + `/regAddress/list/byAdminAndRegisterId/${userObj.admin.id}/${ad}`;
    const result = await fetch(api);
    const getResult = await result.json();
    setRegAddress1(getResult[0] && getResult[0] ? getResult[0] : "");
    setRegAddress2(getResult[1] && getResult[1] ? getResult[1] : "");
    // console.log(getResult);
  }

  async function getCart() {
    const api =
    localApi + `/cart/expireCart/byAdminAndClientIdAndAcitve/${userObj.admin.id}/${client}?active=0`;
    const result = await fetch(api);
    const getResult = await result.json();
    setViewCart(getResult && getResult);
    // console.log(getResult);
  }

  useEffect(() => {
    getProd();
    getClient();
    getCart();
  }, []);

  // setTimeout(() => {
  //   window.scroll({
  //     top: document.body.offsetHeight,
  //     left: 0,
  //     behavior: "smooth",
  //   });
  // }, 0);

  return (
    <div className={classTable.productPage}>
      {/* <Card>
      <GridContainer justify="center" alignItems="center"> */}
        <div className={classTable.container} style={{ border: "1px solid #000000" }}>
          <GridContainer className={classTable.borderLine}>
            <GridItem md={2} sm={2} xs={2}>
              <img 
              src={userObj.admin.logo != null ? localApi + `/admin/logoImageDownload/${userObj.admin.logo}?id=${userObj.admin.id}` : logo} 
              style={{ marginTop: "10px", height: "100px" }} />
            </GridItem>
            <GridItem
              md={8}
              sm={8}
              xs={8}
              className={
                classTable.mlAuto +
                " " +
                classTable.mrAuto +
                " " +
                classTable.textCenter
              }
            >
              <span className={classTable.invoiceName}>PER FORMA INVOICE</span>
              <br />
              <span className={classTable.companyName}>
                {adminAddress.map((a) => a.company)}
              </span>
              <br />
              <div className={classTable.space}>
                <span className={classTable.address}>
                  {adminAddress.map((a) => a.plotHouseNo)}{" "}
                  {adminAddress.map((a) => a.areaColony)}{" "}
                  {adminAddress.map((a) => a.landMark)}{" "}
                  {adminAddress.map((a) => a.road)}
                  <br />
                  {adminAddress.map((a) => a.city)} {"-"}{" "}
                  {adminAddress.map((a) => a.zipCode)},{" "}
                  {adminAddress.map((a) => a.state)}, INDIA.
                  <br />{" "}
                  <strong className={classTable.gst}>
                    GSTIN : {adminAddress.map((a) => a.gstIn)}
                  </strong>{" "}
                </span>
                <br />
                <span className={classTable.mobileTel}>
                  <ImMobile /> {adminAddress.map((a) => a.mobile)}{" "}
                  <IoLogoWhatsapp /> {adminAddress.map((a) => a.whatsApp)}{" "}
                  <MdEmail /> {userObj.email}
                  {/* {adminAddress.map((a) => a.email)} */}
                </span>
              </div>
            </GridItem>
            <GridItem md={2} sm={2} xs={2} className={classTable.originalCopy}>
              <span>Original Copy</span>
            </GridItem>
          </GridContainer>
          <GridContainer className={classTable.borderLine}>
            <GridItem
              md={6}
              sm={6}
              xs={6}
              className={classTable.space + " " + classTable.verticalLine}
            >
              <span
                className={classTable.invoiceDetailsHead}
                style={{ textDecorationLine: "underline" }}
              >
                Billed to :-
              </span>
              <br />
              <span className={classTable.invoicedetailCom}>
                {regAddress1 && regAddress1.company
                  ? regAddress1.company
                  : regAddress1 &&
                    regAddress1.firstName + " " + regAddress1 &&
                    regAddress1.lastName}
              </span>
              <br />
              <span className={classTable.invoicedetail}>
                {regAddress1 && regAddress1.plotHouseNo}{" "}
                {regAddress1 && regAddress1.areaColony}
              </span>
              <br />
              <span className={classTable.invoicedetail}>
                {regAddress1 && regAddress1.landMark}{" "}
                {regAddress1 && regAddress1.road}{" "}
                {regAddress1 && regAddress1.zipCode}
              </span>{" "}
              <br />
              <span className={classTable.invoicedetail}>
                {regAddress1 && regAddress1.city}{" "}
                {regAddress1 && regAddress1.state}
              </span>
              <br />
              <span className={classTable.invoicedetail}>
                {regAddress1 && regAddress1.mobile ? <ImMobile /> : ""}{" "}
                {regAddress1 && regAddress1.mobile ? regAddress1.mobile : ""}{" "}
                {regAddress1 && regAddress1.whatsApp ? <IoLogoWhatsapp /> : ""}{" "}
                {regAddress1 && regAddress1.whatsApp
                  ? regAddress1.whatsApp
                  : ""}
              </span>
              <br />
              <span
                className={classTable.invoicedetail + " " + classTable.gstIn}
              >
                {regAddress1 && regAddress1.gstIn
                  ? "GSTIN / UIN"
                  : "Aadhar No."}{" "}
                &nbsp;&nbsp;:{" "}
                {regAddress1 && regAddress1.gstIn
                  ? regAddress1.gstIn
                  : regAddress1 && regAddress1.aadharNumber}
              </span>
            </GridItem>
            <GridItem md={6} sm={6} xs={6} className={classTable.space}>
              <span
                className={classTable.invoiceDetailsHead}
                style={{ textDecorationLine: "underline" }}
              >
                Shipped to :-
              </span>
              <br />
              <span className={classTable.invoicedetailCom}>
                {regAddress2 && regAddress2.company
                  ? regAddress2.company
                    ? regAddress2.company
                    : regAddress2 &&
                      regAddress2.firstName + " " + regAddress2 &&
                      regAddress2.lastName
                  : regAddress1.company
                  ? regAddress1.company
                  : regAddress1 &&
                    regAddress1.firstName + " " + regAddress1 &&
                    regAddress1.lastName}
              </span>
              <br />
              <span className={classTable.invoicedetail}>
                {regAddress2 && regAddress2.plotHouseNo
                  ? regAddress2.plotHouseNo
                  : regAddress1 && regAddress1.plotHouseNo}{" "}
                {regAddress2 && regAddress2.areaColony
                  ? regAddress2.areaColony
                  : regAddress1 && regAddress1.areaColony}
              </span>{" "}
              <br />
              <span className={classTable.invoicedetail}>
                {regAddress2 && regAddress2.landMark
                  ? regAddress2.landMark
                  : regAddress1 && regAddress1.landMark}{" "}
                {regAddress2 && regAddress2.road
                  ? regAddress2.road
                  : regAddress1 && regAddress1.road}{" "}
                {regAddress2 && regAddress2.zipCode
                  ? regAddress2.zipCode
                  : regAddress1 && regAddress1.zipCode}{" "}
              </span>{" "}
              <br />
              <span className={classTable.invoicedetail}>
                {regAddress2 && regAddress2.city
                  ? regAddress2.city
                  : regAddress1 && regAddress1.city}{" "}
                {regAddress2 && regAddress2.state
                  ? regAddress2.state
                  : regAddress1 && regAddress1.state}
              </span>
              <br />
              <span className={classTable.invoicedetail}>
                {regAddress2 && regAddress2.mobile ? (
                  <ImMobile />
                ) : regAddress1 && regAddress1.mobile ? (
                  <ImMobile />
                ) : (
                  ""
                )}{" "}
                {regAddress2 && regAddress2.mobile
                  ? regAddress2.mobile
                  : regAddress1 && regAddress1.mobile
                  ? regAddress1.mobile
                  : ""}{" "}
                {regAddress2 && regAddress2.whatsApp ? (
                  <TiPhoneOutline />
                ) : regAddress1 && regAddress1.whatsApp ? (
                  <TiPhoneOutline />
                ) : (
                  ""
                )}{" "}
                {regAddress2 && regAddress2.whatsApp
                  ? regAddress2.whatsApp
                  : regAddress1 && regAddress1.whatsApp
                  ? regAddress1.whatsApp
                  : ""}
              </span>
              <br />
              <span
                className={classTable.invoicedetail + " " + classTable.gstIn}
              >
                {regAddress2 && regAddress2.gstIn
                  ? regAddress2.gstIn
                    ? "GSTIN / UIN :"
                    : "Aadhar No. :"
                  : regAddress1.gstIn
                  ? "GSTIN / UIN :"
                  : "Aadhar No. :"}
                {regAddress2 && regAddress2.gstIn
                  ? regAddress2.gstIn
                    ? regAddress2.gstIn
                    : regAddress2 && regAddress2.aadharNumber
                  : regAddress1.gstIn
                  ? regAddress1.gstIn
                  : regAddress1 && regAddress1.aadharNumber}
              </span>
            </GridItem>
          </GridContainer>
          <Table
            tableHead={[
              <span className={classTable.tableHead} key="sr">
                Sr. No.
              </span>,
              "",
              <span className={classTable.tableHead} key="des">
                Description of Goods
              </span>,
              <span
                className={classTable.tableHead + " " + classTable.headRight}
                key="ctn"
              >
                Carton
              </span>,
              <span
                className={classTable.tableHead + " " + classTable.headRight}
                key="qty"
              >
                Quantity
              </span>,
              <span
                className={classTable.tableHead + " " + classTable.headRight}
                key="pri"
              >
                ₹ Price
              </span>,
              <span
                className={classTable.tableHead + " " + classTable.headRight}
                key="amt"
              >
                ₹ Amount
              </span>,
              "",
            ]}
            tableData={[
              ...viewCart.map((c, index) => [
                <span className={classTable.listNumber} key={c.id}>
                  {index + 1}
                </span>,
                <div className={classTable.imgContainer} key={c.id}>
                  <span className={classTable.listDates}>
                    {/* Date: {c.createDate.slice(0, -19)} */}
                  </span>
                  <img
                    src={
                      c.cartImage[0].cropImageUrl +
                      "?id=" +
                      c.cartImage[0].imgId
                    }
                    className={classTable.img}
                    style={{ width: "70px", height: "84px" }}
                  />
                  {/* {c.cartImage.map((ci) => (
                    <img
                      key={ci.id}
                      src={ci.cropImageUrl + "?id=" + ci.imgId}
                      alt="..."
                      className={classTable.img}
                      style={{width: "70px", height: "84px"}}
                    />
                  ))} */}
                </div>,
                <span key={c.id}>
                  <h6 className={classTable.listDescriptions}>
                    {c.cartChild.map((f) =>
                      f.title === "Plating Gold Plated" ? f.title.slice(8) : ""
                    )}{" "}
                    {c.cartParent.title} {c.cartRawPrice.title} <br />
                    {c.cartCategory.title} with {c.cartPacking.type}
                    <br />
                    {c.readyGram * c.cartSets.pcs} Avg Gram of {c.cartSets.setDesc}
                    <br />
                    Sets Price :{" "}
                    {c.gstCash === false
                      ? (c.cashPrice / c.cartBox.box).toFixed(2)
                      : (c.taxInclude / c.cartBox.box).toFixed(2)}
                    /- {c.gstCash === false
                        ? "Cash"
                        : "IncTax"}
                    <br />
                    Metal Rate : ₹ {c.cartRawPrice.price + c.cartRawPrice.different}/-
                    Advance : ₹
                    {Math.round(
                      c.metalGram *
                        c.cartSets.pcs *
                        c.cartBox.box *
                        c.productSize.grandTotal *
                        (c.cartRawPrice.price + c.cartRawPrice.different)
                    )}
                  </h6>
                  {/* <br /> */}
                  {/* <small className={classTable.listDescriptions}>
                      {c.readyGram * c.sets.pcs} Avg Gram of{" "}
                      {c.sets.setDesc}
                      {(
                        c.readyGram * c.sets.pcs +
                        (c?.mixmmList
                          ? c.mixmmList
                              .map((mix) => mix.sets.pcs)
                              .reduce((a, b) => a + b, 0) *
                            c.mixmmList
                              .map((mix) => mix.metalOption.readygram)
                              .reduce((a, b) => a + b, 0)
                          : 0)
                      ).toFixed(4)}{" "}
                      Avg Gram of{" "}
                      {c.sets.pcs +
                        (c?.mixmmList
                          ? c.mixmmList
                              .map((mix) => mix.sets.pcs)
                              .reduce((a, b) => a + b, 0)
                          : 0)}{" "}
                      {c.sets.setDesc.slice(2)}
                    </small> */}
                  {/* <br /> */}
                  {/* <small className={classTable.listDescription}>
                    Sets Price :{" "}
                    {c.gstCash === false
                      ? (c.cashPrice / c.box.box).toFixed(2)
                      : (c.taxInclude / c.box.box).toFixed(2)}
                    /- IncTax
                  </small> */}
                  {/* <br /> */}
                  {/* <small className={classTable.listDescriptions}> */}
                  {/* Metal Rate : ₹ {c.rawPrice.price + c.rawPrice.different}/-
                    Advance : ₹
                    {Math.round(
                      c.metalGram *
                        c.sets.pcs *
                        c.box.box *
                        c.productSize.grandTotal *
                        (c.rawPrice.price + c.rawPrice.different)
                    )} */}
                  {/* {Math.round(
                        c.metalOption.metalgram *
                          c.sets.pcs *
                          c.box.box *
                          c.total *
                          c.metal.price +
                          (c.mixmmList && c.mixmmList
                            ? c.mixmmList
                                .map(
                                  (m) =>
                                    m.metalOption.metalgram *
                                    m.sets.pcs *
                                    m.metal.price
                                )
                                .reduce((a, b) => a + b, 0) *
                              c.box.box *
                              c.total
                            : "")
                      )} */}
                  {/* /-
                  </small>
                  <br /> */}

                  <GridContainer className={classTable.sizePadding}>
                    {c.productSize.s1x8 != 0 ? (
                      <span
                        className={
                          classTable.textCenter +
                          " " +
                          classTable.sizeSpan +
                          " " +
                          classTable.listDescriptionMM
                        }
                      >
                        {c.productSize.s1x8 == null
                          ? ""
                          : c.productSize.s1x8 == 0
                          ? ""
                          : "1x8"}
                        <br />
                        {c.productSize.s1x8 == null
                          ? ""
                          : c.productSize.s1x8 == 0
                          ? ""
                          : c.productSize.s1x8}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.productSize.s1x10 != 0 ? (
                      <span
                        className={
                          classTable.textCenter +
                          " " +
                          classTable.sizeSpan +
                          " " +
                          classTable.listDescriptionMM
                        }
                      >
                        {c.productSize.s1x10 == null
                          ? ""
                          : c.productSize.s1x10 == 0
                          ? ""
                          : "1x10"}
                        <br />
                        {c.productSize.s1x10 == null
                          ? ""
                          : c.productSize.s1x10 == 0
                          ? ""
                          : c.productSize.s1x10}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.productSize.s1x12 != 0 ? (
                      <span
                        className={
                          classTable.textCenter +
                          " " +
                          classTable.sizeSpan +
                          " " +
                          classTable.listDescriptionMM
                        }
                      >
                        {c.productSize.s1x12 == null
                          ? ""
                          : c.productSize.s1x12 == 0
                          ? ""
                          : "1x12"}
                        <br />
                        {c.productSize.s1x12 == null
                          ? ""
                          : c.productSize.s1x12 == 0
                          ? ""
                          : c.productSize.s1x12}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.productSize.s1x14 != 0 ? (
                      <span
                        className={
                          classTable.textCenter +
                          " " +
                          classTable.sizeSpan +
                          " " +
                          classTable.listDescriptionMM
                        }
                      >
                        {c.productSize.s1x14 == null
                          ? ""
                          : c.productSize.s1x14 == 0
                          ? ""
                          : "1x14"}
                        <br />
                        {c.productSize.s1x14 == null
                          ? ""
                          : c.productSize.s1x14 == 0
                          ? ""
                          : c.productSize.s1x14}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.productSize.s2x0 != 0 ? (
                      <span
                        className={
                          classTable.textCenter +
                          " " +
                          classTable.sizeSpan +
                          " " +
                          classTable.listDescriptionMM
                        }
                      >
                        {c.productSize.s2x0 == null
                          ? ""
                          : c.productSize.s2x0 == 0
                          ? ""
                          : "2x0"}
                        <br />
                        {c.productSize.s2x0 == null
                          ? ""
                          : c.productSize.s2x0 == 0
                          ? ""
                          : c.productSize.s2x0}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.productSize.s2x2 != 0 ? (
                      <span
                        className={
                          classTable.textCenter +
                          " " +
                          classTable.sizeSpan +
                          " " +
                          classTable.listDescriptionMM
                        }
                      >
                        {c.productSize.s2x2 == null
                          ? ""
                          : c.productSize.s2x2 == 0
                          ? ""
                          : "2x2"}
                        <br />
                        {c.productSize.s2x2 == null
                          ? ""
                          : c.productSize.s2x2 == 0
                          ? ""
                          : c.productSize.s2x2}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.productSize.s2x4 != 0 ? (
                      <span
                        className={
                          classTable.textCenter +
                          " " +
                          classTable.sizeSpan +
                          " " +
                          classTable.listDescriptionMM
                        }
                      >
                        {c.productSize.s2x4 == null
                          ? ""
                          : c.productSize.s2x4 == 0
                          ? ""
                          : "2x4"}
                        <br />
                        {c.productSize.s2x4 == null
                          ? ""
                          : c.productSize.s2x4 == 0
                          ? ""
                          : c.productSize.s2x4}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.productSize.s2x6 != 0 ? (
                      <span
                        className={
                          classTable.textCenter +
                          " " +
                          classTable.sizeSpan +
                          " " +
                          classTable.listDescriptionMM
                        }
                      >
                        {c.productSize.s2x6 == null
                          ? ""
                          : c.productSize.s2x6 == 0
                          ? ""
                          : "2x6"}
                        <br />
                        {c.productSize.s2x6 == null
                          ? ""
                          : c.productSize.s2x6 == 0
                          ? ""
                          : c.productSize.s2x6}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.productSize.s2x8 != 0 ? (
                      <span
                        className={
                          classTable.textCenter +
                          " " +
                          classTable.sizeSpan +
                          " " +
                          classTable.listDescriptionMM
                        }
                      >
                        {c.productSize.s2x8 == null
                          ? ""
                          : c.productSize.s2x8 == 0
                          ? ""
                          : "2x8"}
                        <br />
                        {c.productSize.s2x8 == null
                          ? ""
                          : c.productSize.s2x8 == 0
                          ? ""
                          : c.productSize.s2x8}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.productSize.s2x10 != 0 ? (
                      <span
                        className={
                          classTable.textCenter +
                          " " +
                          classTable.sizeSpan +
                          " " +
                          classTable.listDescriptionMM
                        }
                      >
                        {c.productSize.s2x10 == null
                          ? ""
                          : c.productSize.s2x10 == 0
                          ? ""
                          : "2x10"}
                        <br />
                        {c.productSize.s2x10 == null
                          ? ""
                          : c.productSize.s2x10 == 0
                          ? ""
                          : c.productSize.s2x10}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.productSize.s2x12 != 0 ? (
                      <span
                        className={
                          classTable.textCenter +
                          " " +
                          classTable.sizeSpan +
                          " " +
                          classTable.listDescriptionMM
                        }
                      >
                        {c.productSize.s2x12 == null
                          ? ""
                          : c.productSize.s2x12 == 0
                          ? ""
                          : "2x12"}
                        <br />
                        {c.productSize.s2x12 == null
                          ? ""
                          : c.productSize.s2x12 == 0
                          ? ""
                          : c.productSize.s2x12}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.productSize.s2x14 != 0 ? (
                      <span
                        className={
                          classTable.textCenter +
                          " " +
                          classTable.sizeSpan +
                          " " +
                          classTable.listDescriptionMM
                        }
                      >
                        {c.productSize.s2x14 == null
                          ? ""
                          : c.productSize.s2x14 == 0
                          ? ""
                          : "2x14"}
                        <br />
                        {c.productSize.s2x14 == null
                          ? ""
                          : c.productSize.s2x14 == 0
                          ? ""
                          : c.productSize.s2x14}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.productSize.s3x0 != 0 ? (
                      <span
                        className={
                          classTable.textCenter +
                          " " +
                          classTable.sizeSpan +
                          " " +
                          classTable.listDescriptionMM
                        }
                      >
                        {c.productSize.s3x0 == null
                          ? ""
                          : c.productSize.s3x0 == 0
                          ? ""
                          : "3x0"}
                        <br />
                        {c.productSize.s3x0 == null
                          ? ""
                          : c.productSize.s3x0 == 0
                          ? ""
                          : c.productSize.s3x0}
                      </span>
                    ) : (
                      ""
                    )}
                  </GridContainer>
                  <GridContainer className={classTable.sizePadding}>
                    {c.productSize.s1x9 != 0 ? (
                      <span
                        className={
                          classTable.textCenter +
                          " " +
                          classTable.sizeSpan +
                          " " +
                          classTable.listDescriptionMM
                        }
                      >
                        {c.productSize.s1x9 == null
                          ? ""
                          : c.productSize.s1x9 == 0
                          ? ""
                          : "1x9"}
                        <br />
                        {c.productSize.s1x9 == null
                          ? ""
                          : c.productSize.s1x9 == 0
                          ? ""
                          : c.productSize.s1x9}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.productSize.s1x11 != 0 ? (
                      <span
                        className={
                          classTable.textCenter +
                          " " +
                          classTable.sizeSpan +
                          " " +
                          classTable.listDescriptionMM
                        }
                      >
                        {c.productSize.s1x11 == null
                          ? ""
                          : c.productSize.s1x11 == 0
                          ? ""
                          : "1x11"}
                        <br />
                        {c.productSize.s1x11 == null
                          ? ""
                          : c.productSize.s1x11 == 0
                          ? ""
                          : c.productSize.s1x11}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.productSize.s1x13 != 0 ? (
                      <span
                        className={
                          classTable.textCenter +
                          " " +
                          classTable.sizeSpan +
                          " " +
                          classTable.listDescriptionMM
                        }
                      >
                        {c.productSize.s1x13 == null
                          ? ""
                          : c.productSize.s1x13 == 0
                          ? ""
                          : "1x13"}
                        <br />
                        {c.productSize.s1x13 == null
                          ? ""
                          : c.productSize.s1x13 == 0
                          ? ""
                          : c.productSize.s1x13}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.productSize.s1x15 != 0 ? (
                      <span
                        className={
                          classTable.textCenter +
                          " " +
                          classTable.sizeSpan +
                          " " +
                          classTable.listDescriptionMM
                        }
                      >
                        {c.productSize.s1x15 == null
                          ? ""
                          : c.productSize.s1x15 == 0
                          ? ""
                          : "1x15"}
                        <br />
                        {c.productSize.s1x15 == null
                          ? ""
                          : c.productSize.s1x15 == 0
                          ? ""
                          : c.productSize.s1x15}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.productSize.s2x1 != 0 ? (
                      <span
                        className={
                          classTable.textCenter +
                          " " +
                          classTable.sizeSpan +
                          " " +
                          classTable.listDescriptionMM
                        }
                      >
                        {c.productSize.s2x1 == null
                          ? ""
                          : c.productSize.s2x1 == 0
                          ? ""
                          : "2x1"}
                        <br />
                        {c.productSize.s2x1 == null
                          ? ""
                          : c.productSize.s2x1 == 0
                          ? ""
                          : c.productSize.s2x1}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.productSize.s2x3 != 0 ? (
                      <span
                        className={
                          classTable.textCenter +
                          " " +
                          classTable.sizeSpan +
                          " " +
                          classTable.listDescriptionMM
                        }
                      >
                        {c.productSize.s2x3 == null
                          ? ""
                          : c.productSize.s2x3 == 0
                          ? ""
                          : "2x3"}
                        <br />
                        {c.productSize.s2x3 == null
                          ? ""
                          : c.productSize.s2x3 == 0
                          ? ""
                          : c.productSize.s2x3}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.productSize.s2x5 != 0 ? (
                      <span
                        className={
                          classTable.textCenter +
                          " " +
                          classTable.sizeSpan +
                          " " +
                          classTable.listDescriptionMM
                        }
                      >
                        {c.productSize.s2x5 == null
                          ? ""
                          : c.productSize.s2x5 == 0
                          ? ""
                          : "2x5"}
                        <br />
                        {c.productSize.s2x5 == null
                          ? ""
                          : c.productSize.s2x5 == 0
                          ? ""
                          : c.productSize.s2x5}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.productSize.s2x7 != 0 ? (
                      <span
                        className={
                          classTable.textCenter +
                          " " +
                          classTable.sizeSpan +
                          " " +
                          classTable.listDescriptionMM
                        }
                      >
                        {c.productSize.s2x7 == null
                          ? ""
                          : c.productSize.s2x7 == 0
                          ? ""
                          : "2x7"}
                        <br />
                        {c.productSize.s2x7 == null
                          ? ""
                          : c.productSize.s2x7 == 0
                          ? ""
                          : c.productSize.s2x7}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.productSize.s2x9 != 0 ? (
                      <span
                        className={
                          classTable.textCenter +
                          " " +
                          classTable.sizeSpan +
                          " " +
                          classTable.listDescriptionMM
                        }
                      >
                        {c.productSize.s2x9 == null
                          ? ""
                          : c.productSize.s2x9 == 0
                          ? ""
                          : "2x9"}
                        <br />
                        {c.productSize.s2x9 == null
                          ? ""
                          : c.productSize.s2x9 == 0
                          ? ""
                          : c.productSize.s2x9}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.productSize.s2x11 != 0 ? (
                      <span
                        className={
                          classTable.textCenter +
                          " " +
                          classTable.sizeSpan +
                          " " +
                          classTable.listDescriptionMM
                        }
                      >
                        {c.productSize.s2x11 == null
                          ? ""
                          : c.productSize.s2x11 == 0
                          ? ""
                          : "2x11"}
                        <br />
                        {c.productSize.s2x11 == null
                          ? ""
                          : c.productSize.s2x11 == 0
                          ? ""
                          : c.productSize.s2x11}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.productSize.s2x13 != 0 ? (
                      <span
                        className={
                          classTable.textCenter +
                          " " +
                          classTable.sizeSpan +
                          " " +
                          classTable.listDescriptionMM
                        }
                      >
                        {c.productSize.s2x13 == null
                          ? ""
                          : c.productSize.s2x13 == 0
                          ? ""
                          : "2x13"}
                        <br />
                        {c.productSize.s2x13 == null
                          ? ""
                          : c.productSize.s2x13 == 0
                          ? ""
                          : c.productSize.s2x13}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.productSize.s2x15 != 0 ? (
                      <span
                        className={
                          classTable.textCenter +
                          " " +
                          classTable.sizeSpan +
                          " " +
                          classTable.listDescriptionMM
                        }
                      >
                        {c.productSize.s2x15 == null
                          ? ""
                          : c.productSize.s2x15 == 0
                          ? ""
                          : "2x15"}
                        <br />
                        {c.productSize.s2x15 == null
                          ? ""
                          : c.productSize.s2x15 == 0
                          ? ""
                          : c.productSize.s2x15}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.productSize.s3x1 != 0 ? (
                      <span
                        className={
                          classTable.textCenter +
                          " " +
                          classTable.sizeSpan +
                          " " +
                          classTable.listDescriptionMM
                        }
                      >
                        {c.productSize.s3x1 == null
                          ? ""
                          : c.productSize.s3x1 == 0
                          ? ""
                          : "3x1"}
                        <br />
                        {c.productSize.s3x1 == null
                          ? ""
                          : c.productSize.s3x1 == 0
                          ? ""
                          : c.productSize.s3x1}
                      </span>
                    ) : (
                      ""
                    )}
                  </GridContainer>
                  {/* <span className={classTable.listDescription}>
                      {c && c.name ? c.name + ", " : ""}{" "}
                      {c && c.mycomment ? c.mycomment : ""}
                    </span> */}
                </span>,
                <span
                  className={classTable.headRight + " " + classTable.listNumber}
                  key={c.id}
                >
                  {c.cartQuantity}
                </span>,
                <span
                  className={classTable.headRight + " " + classTable.listNumber}
                  key={c.id}
                >
                  {c.productSize.grandTotal}
                </span>,
                <span
                  className={classTable.headRight + " " + classTable.listNumber}
                  key={c.id}
                >
                  {c.gstCash === true
                    ? c.taxInclude.toFixed(2)
                    : c.cashPrice.toFixed(2)}
                </span>,
                <span
                  className={classTable.headRight + " " + classTable.listNumber}
                  key={c.id}
                >
                  {Math.round(c.amount)}
                </span>,
                ""
                // <Tooltip
                //   id="close1"
                //   title="Cart Remove"
                //   placement="left"
                //   classes={{ tooltip: classTable.tooltip }}
                //   key={c.id}
                // >
                //   <Button
                //     link
                //     className={classTable.actionButton}
                //     onClick={() => handleitemRemove(c.id)}
                //   >
                //     <Close style={{ color: "red" }} />
                //   </Button>
                // </Tooltip>,
              ]),
              [
                "",
                <span className={classTable.listNumber} key={1}>
                  Carton Charges
                  <br />
                  <small className={classTable.listNumbers}>Grand Total</small>
                </span>,
                <span className={classTable.listNumber} key={2}>
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Advance - ₹{" "}
                  {Math.round(
                    viewCart.reduce(
                      (a, b) =>
                        a +
                        b.metalGram *
                          b.cartSets.pcs *
                          b.cartBox.box *
                          b.productSize.grandTotal *
                          (b.cartRawPrice.price + b.cartRawPrice.different),
                      0
                    )
                  ) + "/-"}
                  {/* {state.map((c) => {
                      totalCal =
                        totalCal +
                        c.metalGram *
                          c.sets.pcs *
                          c.box.box *
                          c.total *
                          c.metal.price;
                      return c.mixmmList && c.mixmmList
                        ? c.mixmmList.map((m) => {
                            totalCalMix =
                              totalCalMix +
                              m.metalOption.metalgram *
                                m.sets.pcs *
                                m.metal.price *
                                c.box.box *
                                c.total;
                            totalSum = totalCalMix + totalCal;
                            return "";
                          })
                        : "";
                    })}
                    Advance - ₹
                    {Math.round((totalSum = totalCalMix + totalCal)) + "/-"} */}
                </span>,
                <span key={3}>
                  <br />
                  <span
                    className={
                      classTable.headRight + " " + classTable.listNumber
                    }
                  >
                    Carton-
                    {viewCart.reduce(
                      (a, b) => a + b.cartQuantity,
                      0
                    )}
                  </span>
                </span>,
                <span key={3}>
                  <br />
                  <span
                    className={
                      classTable.headRight + " " + classTable.listNumber
                    }
                  >
                    Unit-
                    {viewCart &&
                      viewCart.reduce(
                        (a, b) => a + b.productSize.grandTotal,
                        0
                      )}
                  </span>
                </span>,
                "",
                <span key={4}>
                  <span
                    className={
                      classTable.headRight + " " + classTable.listNumber
                    }
                  >
                    ₹{" "}
                    {viewCart.reduce((a, b) => a + b.cartPackCharge * b.cartQuantity, 0) + "/-"}
                  </span>
                  <br />
                  <span
                    className={
                      classTable.headRight + " " + classTable.listNumber
                    }
                  >
                    ₹{" "}
                    {Math.round(
                      viewCart.reduce(
                        (a, b) => a + b.amount + b.cartPackCharge * b.cartQuantity,
                        0
                      )
                    ) + "/-"}
                  </span>
                </span>,
                "",
              ],
            ]}
          />
          <hr style={{ borderBottom: "1px solid #00000066" }} />
          <GridItem className={classTable.textCenter}>
            <h5>
              <strong>Terms & Condition</strong>
            </h5>
          </GridItem>
          <hr style={{ borderBottom: "1px solid #00000066" }} />
          <GridItem className={classTable.condition}>
            <ol>
              <li>
                Payment : Advance{" "}
                <span className={classTable.termDescription}>
                  {/* Rs. {Math.round(totalSum)}/- */}
                  Rs. ₹{" "}
                  {Math.round(
                    viewCart.reduce(
                      (a, b) =>
                        a +
                        b.metalGram *
                          b.cartSets.pcs *
                          b.cartBox.box *
                          b.productSize.grandTotal *
                          (b.cartRawPrice.price + b.cartRawPrice.different),
                      0
                    )
                  ) + "/-"}
                </span>{" "}
                of total amount along with purchase order 20% when dispatchof
                1st shipment and Remaining 15% will be payable on confirmation
                of completion of goods ordered,
              </li>
              <li>
                Delivery: 50% of the total goods will be delivered in the first
                shipment. Remaining 50% will be delivered on the receipt of
                total outstanding amount of whole purchase order (I.E remaining
                15%),
              </li>
              <li>Packing and Transportation charges will be extra,</li>
              <li>Insurance covered, if paid 3% of the total purchase,</li>
              <li>Proposal is valid for 10 days from,</li>
            </ol>
          </GridItem>
          <div className={classTable.brand}>
            <Button
              className={classTable.print}
              color="rose"
              round
              onClick={() => {
                window.print();
              }}
            >
              Print
            </Button>
          </div>
        </div>
      {/* </GridContainer>
      </Card> */}
    </div>
  );
}
