import React, { useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { localApi } from "utils/imgApi";
import Card from "components/Card/Card.js";
import { useHistory, useLocation } from "react-router-dom";

// material-ui icons
import Tooltip from "@material-ui/core/Tooltip";
import Close from "@material-ui/icons/Close";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";

import SweetAlert from "react-bootstrap-sweetalert";

import styles from "assets/jss/material-dashboard-pro-react/views/viewCartStyle";
import styDrop from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import style from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(styDrop);
const useStyl = makeStyles(style);

export default function ViewCart() {
  const [viewCart, setViewCart] = React.useState([]);
  const [updateAdd, setUpdateAdd] = React.useState([]);
  const [clientId, setClientId] = React.useState("");
  const [clientid, setClientid] = React.useState("");
  const [bootselect, setBootSelect] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);
  const [alertMsg, alertMsgOps] = React.useState("");
  const [alertType, alertTypeOps] = React.useState("");

  const [gstAddress, setGSTAddress] = React.useState("");
  const [shipAddress, setShipAddress] = React.useState("");
  const [branchAddress, setBranchAddress] = React.useState("");
  const [deliAddress, setDeliAddress] = React.useState([]);

  const [cartId, setCartId] = React.useState();
  const [submittedCart, setSubmittedCart] = React.useState(false);
  const [submittedsCart, setSubmittedsCart] = React.useState(false);
  const [submittedConfirmCart, setSubmittedConfirmCart] = React.useState(false);

  const classes = useStyles();
  const classe = useStyle();
  const classeAlert = useStyl();
  const history = useHistory();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  const { search } = useLocation();
  const client = new URLSearchParams(search).get("client");
  const address = new URLSearchParams(search).get("address");
  const transport = new URLSearchParams(search).get("transport");
  const reg = new URLSearchParams(search).get("reg");

  React.useEffect(() => {
    axios
      .get(
        localApi +
          `/cart/expireCart/byAdminAndClientIdAndAcitve/${userObj.admin.id}/${client}?active=0`
      )
      .then((res) => {
        setViewCart(res && res.data);
      });
  }, []);

  React.useEffect(() => {
    const deliveryAdd = [];
    axios
      .get(
        localApi +
          `/regAddress/list/byAdminAndRegisterId/${userObj.admin.id}/${reg}`
      )
      .then((res) => {
        // console.log(res.data);
        // setUpdateAdd(res.data);
        const gstAddress = res.data.filter((a) =>
          a && a.addressType === "GSTIN ADDRESS"
            ? a.addressType === "GSTIN ADDRESS"
            : a.addressType === "AADHAR ADDRESS"
            ? a.addressType === "AADHAR ADDRESS"
            : a.addressType === "OTHER ADDRESS"
        )[0];
        setUpdateAdd(gstAddress && gstAddress);
        const { id, ...restGSTAdd } = Object.assign({}, gstAddress);
        deliveryAdd.push(restGSTAdd);
      });

    axios.get(localApi + `/regAddress/byId/${address}`).then((r) => {
      const { id, ...restShipAdd } = Object.assign({}, r.data);
      setShipAddress(restShipAdd);
      deliveryAdd.push(restShipAdd);
    });

    setDeliAddress(deliveryAdd);

    axios.get(localApi + `/branch/findById/${transport}`).then((b) => {
      const { id, ...restBranchAdd } = Object.assign({}, b.data);
      setBranchAddress(restBranchAdd);
    });
  }, []);

  const handleRemove = (c) => {
    setSubmittedsCart(true);
    setCartId(c);
  };
  const handleConfirm = () => {
    fetch(
      localApi +
        `/cart/delete/byAdminAndCartId/${userObj.admin.id}/${cartId.id}`,
      {
        method: "DELETE",
      }
    )
      .then((res) => res.json())
      .then((res) => {
        // console.log(res);
        if (res.message === "Deleted Successfully !!!") {
          alertTypeOps("success");
          alertMsgOps("Deleted Succesfully !!!");
          setSubmittedConfirmCart(true);
        }
      });
  };

  const hideAlertCart = () => {
    setSubmittedCart(false);
    window.location.reload(false);
  };

  const hideAlertsCart = () => {
    setSubmittedsCart(false);
    setSubmittedConfirmCart(false);
    window.location.reload(false);
  };

  const confirmOrder = () => {
    const cartMid = viewCart.map((i) => {
      return i.id;
    });

    const cartM = viewCart.map((i) => {
      return {
        cartId: i.id,
        adminId: i.c_adminid,
        clientId: i.cId,
      };
    });

    const cartClientid = viewCart.map((i) => {
      return i.cId;
    });

    const sType = viewCart.map((m) => m.saleType)[0];
    const gcType = viewCart.map((m) => m.gstCash)[0];

    // cartMid.forEach((x) =>
    //   axios
    //     .put(
    //       localApi + `/cart/update/byAdminAndCartId/${userObj.admin.id}/${x}`
    //     )
    //     .then((res) => {
    //       // console.log(res.data);
    //       if (res.data.message === "Data Updated Successfully !!!") {
    //         setSubmitted(true);
    //         alertTypeOps("success");
    //         alertMsgOps("Congratulation your Order is Successfully placed");
    //       }
    //     })
    // );

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        cartId: cartMid[0].toString(),
        clientId: cartClientid[0].toString(),
        ordersCart: cartM,
        firstName: updateAdd.firstName,
        lastName: updateAdd.lastName,
        company: updateAdd.company,
        deliveryAddress: deliAddress,
        transbranch: branchAddress,
        saleType: sType,
        gstCash: gcType,
        amount: Math.round(
          viewCart.reduce((a, b) => a + b.amount + b.cartPackCharge * b.cartQuantity, 0)
        ),
        advance: Math.round(
          viewCart.reduce(
            (a, b) =>
              a +
              b.metalGram *
                b.cartSets.pcs *
                b.cartBox.box *
                b.productSize.grandTotal *
                (b.cartRawPrice.price + b.cartRawPrice.different),
            0
          )
        ),
        totalBox: viewCart.reduce((a, b) => a + b.productSize.grandTotal, 0),
        totalCarton: viewCart.reduce((a, b) => a + b.cartQuantity, 0),
        status: 0,
        paymentStatus: 0,
        deliveryStatus: 0,
      }),
    };

    fetch(localApi + `/orders/add/${userObj.admin.id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        if (data.message === "Data Inserted Successfully !!!") {
          setSubmitted(true);
          alertTypeOps("success");
          alertMsgOps("Congratulation");
          cartMid.forEach((x) =>
            axios
              .put(
                localApi +
                  `/cart/update/byAdminAndCartId/${userObj.admin.id}/${x}`
              )
              .then((res) => {
                // console.log(res.data);
                // if (res.data.message === "Data Updated Successfully !!!") {
                //   setSubmitted(true);
                //   alertTypeOps("success");
                //   alertMsgOps("Congratulation");
                // }
              })
          );
        }
      });
  };

  const hideAlert = () => {
    setSubmitted(false);
    history.push("/admin/myOrder");
  };

  // var totalCal = 0;
  // var totalCalMix = 0;
  // var totalSum = 0;

  return (
    <div
      style={{
        marginLeft: "40px",
        marginRight: "40px",
        marginTop: "30px",
        marginBottom: "30px",
      }}
    >
      <Card>
        <GridContainer justify="center" alignItems="center">
          <div className={classes.container}>
            {submitted ? (
              <SweetAlert
                type={alertType}
                style={{ display: "block", marginTop: "100px" }}
                title={alertMsg}
                onConfirm={() => hideAlert()}
                confirmBtnCssClass={
                  classeAlert.button + " " + classeAlert.success
                }
              >Your Order is Successfully placed</SweetAlert>
            ) : null}
            {submittedCart ? (
              <SweetAlert
                type={alertType}
                style={{ display: "block", marginTop: "-100px" }}
                title={alertMsg}
                onConfirm={() => hideAlertCart()}
                confirmBtnCssClass={
                  classeAlert.button + " " + classeAlert.success
                }
              />
            ) : null}
            {submittedsCart ? (
              <SweetAlert
                warning
                style={{ display: "block", marginTop: "100px" }}
                title="Are you sure?"
                onConfirm={handleConfirm}
                onCancel={() => hideAlertsCart()}
                confirmBtnCssClass={
                  classeAlert.button + " " + classeAlert.success
                }
                cancelBtnCssClass={
                  classeAlert.button + " " + classeAlert.danger
                }
                confirmBtnText="Yes, delete it!"
                cancelBtnText="Cancel"
                showCancel
              />
            ) : null}

            {submittedConfirmCart ? (
              <SweetAlert
                success
                style={{ display: "block", marginTop: "100px" }}
                title="Deleted!"
                onConfirm={() => hideAlertsCart()}
                onCancel={() => hideAlertsCart()}
                confirmBtnCssClass={
                  classeAlert.button + " " + classeAlert.success
                }
              >
                Your item has been deleted.
              </SweetAlert>
            ) : null}
            <h3 className={classes.cardTitle}>Shopping Lists</h3>
            <Table
              tableHead={[
                <span className={classes.tableHead} key="sr">
                  Sr.
                  {/* <br /> */}
                  No.
                </span>,
                "",
                <span className={classes.tableHead} key="des">
                  Description of Goods
                </span>,
                <span
                  className={classes.tableHead + " " + classes.headRight}
                  key="ctn"
                >
                  Carton
                </span>,
                <span
                  className={classes.tableHead + " " + classes.headRight}
                  key="qty"
                >
                  Quantity
                </span>,
                <span
                  className={classes.tableHead + " " + classes.headRight}
                  key="pri"
                >
                  Price
                </span>,
                <span
                  className={classes.tableHead + " " + classes.headRight}
                  key="amt"
                >
                  Amount
                </span>,
                "",
              ]}
              tableData={[
                ...viewCart.map((c, index) => [
                  <span className={classes.listNumber} key={c.id}>
                    {index + 1}
                  </span>,
                  <div className={classes.imgContainer} key={c.id}>
                    {/* Date: {c.createDate.slice(0, -19)} */}
                    <img
                      src={
                        c.cartImage[0].cropImageUrl +
                        "?id=" +
                        c.cartImage[0].imgId
                      }
                      className={classes.img}
                      style={{ height: "140px" }}
                      //   style={{ width: "70px", height: "84px" }}
                    />
                    {/* {c.cartImage.map((ci) => (
                                            <img
                                                key={ci.id}
                                                src={
                                                    ci.cropImageUrl +
                                                    "?id=" +
                                                    ci.imgId
                                                }
                                                alt="..."
                                                className={classes.img}
                                                style={{ height: "140px" }}
                                            />
                                        ))} */}
                  </div>,
                  <span key={c.id}>
                    <h6 className={classes.listDescriptions}>
                      {c.cartChild.map((f) =>
                        f.title === "Plating Gold Plated"
                          ? f.title.slice(8)
                          : ""
                      )}{" "}
                      {c.cartParent.title} {c.cartRawPrice.title} <br />
                      {c.cartCategory.title} with {c.cartPacking.type}
                      <br />
                      {c.readyGram * c.cartSets.pcs} Avg Gram of {c.cartSets.setDesc}
                      <br />
                      Sets Price :{" "}
                      {c.gstCash === false
                        ? (c.cashPrice / c.cartBox.box).toFixed(2)
                        : (c.taxInclude / c.cartBox.box).toFixed(2)}
                      /- {c.gstCash === false ? "Cash" : "IncTax"}
                      <br />
                      Metal Rate : ₹{" "}
                      {c.cartRawPrice.price + c.cartRawPrice.different}/-
                      Advance : ₹
                      {Math.round(
                        c.metalGram *
                          c.cartSets.pcs *
                          c.cartBox.box *
                          c.productSize.grandTotal *
                          (c.cartRawPrice.price + c.cartRawPrice.different)
                      )}
                    </h6>
                    {/* <br /> */}
                    {/* <small className={classes.listDescriptions}>
                      {c.readyGram * c.sets.pcs} Avg Gram of{" "}
                      {c.sets.setDesc}
                      {(
                        c.readyGram * c.sets.pcs +
                        (c?.mixmmList
                          ? c.mixmmList
                              .map((mix) => mix.sets.pcs)
                              .reduce((a, b) => a + b, 0) *
                            c.mixmmList
                              .map((mix) => mix.metalOption.readygram)
                              .reduce((a, b) => a + b, 0)
                          : 0)
                      ).toFixed(4)}{" "}
                      Avg Gram of{" "}
                      {c.sets.pcs +
                        (c?.mixmmList
                          ? c.mixmmList
                              .map((mix) => mix.sets.pcs)
                              .reduce((a, b) => a + b, 0)
                          : 0)}{" "}
                      {c.sets.setDesc.slice(2)}
                    </small> */}
                    {/* <br /> */}
                    {/* <small className={classes.listDescription}>
                      Sets Price :{" "}
                      {c.gstCash === false
                        ? (c.cashPrice / c.box.box).toFixed(2)
                        : (c.taxInclude / c.box.box).toFixed(2)}
                      /-{" "}IncTax
                    </small> */}
                    {/* <br /> */}
                    {/* <small className={classes.listDescriptions}> */}
                    {/* Metal Rate : ₹ {c.rawPrice.price + c.rawPrice.different}/- Advance : ₹
                      {Math.round(c.metalGram * c.sets.pcs * c.box.box * c.productSize.grandTotal * (c.rawPrice.price + c.rawPrice.different))} */}
                    {/* {Math.round(
                        c.metalOption.metalgram *
                          c.sets.pcs *
                          c.box.box *
                          c.total *
                          c.metal.price +
                          (c.mixmmList && c.mixmmList
                            ? c.mixmmList
                                .map(
                                  (m) =>
                                    m.metalOption.metalgram *
                                    m.sets.pcs *
                                    m.metal.price
                                )
                                .reduce((a, b) => a + b, 0) *
                              c.box.box *
                              c.total
                            : "")
                      )} */}
                    {/* /- */}

                    {/* </small> */}
                    {/* <br /> */}

                    <GridContainer className={classes.sizePadding}>
                      {c.productSize.s1x8 != 0 ? (
                        <span
                          className={
                            classes.textCenter +
                            " " +
                            classes.sizeSpan +
                            " " +
                            classes.listDescriptionMM
                          }
                        >
                          {c.productSize.s1x8 == null
                            ? ""
                            : c.productSize.s1x8 == 0
                            ? ""
                            : "1x8"}
                          <br />
                          {c.productSize.s1x8 == null
                            ? ""
                            : c.productSize.s1x8 == 0
                            ? ""
                            : c.productSize.s1x8}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s1x10 != 0 ? (
                        <span
                          className={
                            classes.textCenter +
                            " " +
                            classes.sizeSpan +
                            " " +
                            classes.listDescriptionMM
                          }
                        >
                          {c.productSize.s1x10 == null
                            ? ""
                            : c.productSize.s1x10 == 0
                            ? ""
                            : "1x10"}
                          <br />
                          {c.productSize.s1x10 == null
                            ? ""
                            : c.productSize.s1x10 == 0
                            ? ""
                            : c.productSize.s1x10}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s1x12 != 0 ? (
                        <span
                          className={
                            classes.textCenter +
                            " " +
                            classes.sizeSpan +
                            " " +
                            classes.listDescriptionMM
                          }
                        >
                          {c.productSize.s1x12 == null
                            ? ""
                            : c.productSize.s1x12 == 0
                            ? ""
                            : "1x12"}
                          <br />
                          {c.productSize.s1x12 == null
                            ? ""
                            : c.productSize.s1x12 == 0
                            ? ""
                            : c.productSize.s1x12}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s1x14 != 0 ? (
                        <span
                          className={
                            classes.textCenter +
                            " " +
                            classes.sizeSpan +
                            " " +
                            classes.listDescriptionMM
                          }
                        >
                          {c.productSize.s1x14 == null
                            ? ""
                            : c.productSize.s1x14 == 0
                            ? ""
                            : "1x14"}
                          <br />
                          {c.productSize.s1x14 == null
                            ? ""
                            : c.productSize.s1x14 == 0
                            ? ""
                            : c.productSize.s1x14}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s2x0 != 0 ? (
                        <span
                          className={
                            classes.textCenter +
                            " " +
                            classes.sizeSpan +
                            " " +
                            classes.listDescriptionMM
                          }
                        >
                          {c.productSize.s2x0 == null
                            ? ""
                            : c.productSize.s2x0 == 0
                            ? ""
                            : "2x0"}
                          <br />
                          {c.productSize.s2x0 == null
                            ? ""
                            : c.productSize.s2x0 == 0
                            ? ""
                            : c.productSize.s2x0}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s2x2 != 0 ? (
                        <span
                          className={
                            classes.textCenter +
                            " " +
                            classes.sizeSpan +
                            " " +
                            classes.listDescriptionMM
                          }
                        >
                          {c.productSize.s2x2 == null
                            ? ""
                            : c.productSize.s2x2 == 0
                            ? ""
                            : "2x2"}
                          <br />
                          {c.productSize.s2x2 == null
                            ? ""
                            : c.productSize.s2x2 == 0
                            ? ""
                            : c.productSize.s2x2}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s2x4 != 0 ? (
                        <span
                          className={
                            classes.textCenter +
                            " " +
                            classes.sizeSpan +
                            " " +
                            classes.listDescriptionMM
                          }
                        >
                          {c.productSize.s2x4 == null
                            ? ""
                            : c.productSize.s2x4 == 0
                            ? ""
                            : "2x4"}
                          <br />
                          {c.productSize.s2x4 == null
                            ? ""
                            : c.productSize.s2x4 == 0
                            ? ""
                            : c.productSize.s2x4}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s2x6 != 0 ? (
                        <span
                          className={
                            classes.textCenter +
                            " " +
                            classes.sizeSpan +
                            " " +
                            classes.listDescriptionMM
                          }
                        >
                          {c.productSize.s2x6 == null
                            ? ""
                            : c.productSize.s2x6 == 0
                            ? ""
                            : "2x6"}
                          <br />
                          {c.productSize.s2x6 == null
                            ? ""
                            : c.productSize.s2x6 == 0
                            ? ""
                            : c.productSize.s2x6}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s2x8 != 0 ? (
                        <span
                          className={
                            classes.textCenter +
                            " " +
                            classes.sizeSpan +
                            " " +
                            classes.listDescriptionMM
                          }
                        >
                          {c.productSize.s2x8 == null
                            ? ""
                            : c.productSize.s2x8 == 0
                            ? ""
                            : "2x8"}
                          <br />
                          {c.productSize.s2x8 == null
                            ? ""
                            : c.productSize.s2x8 == 0
                            ? ""
                            : c.productSize.s2x8}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s2x10 != 0 ? (
                        <span
                          className={
                            classes.textCenter +
                            " " +
                            classes.sizeSpan +
                            " " +
                            classes.listDescriptionMM
                          }
                        >
                          {c.productSize.s2x10 == null
                            ? ""
                            : c.productSize.s2x10 == 0
                            ? ""
                            : "2x10"}
                          <br />
                          {c.productSize.s2x10 == null
                            ? ""
                            : c.productSize.s2x10 == 0
                            ? ""
                            : c.productSize.s2x10}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s2x12 != 0 ? (
                        <span
                          className={
                            classes.textCenter +
                            " " +
                            classes.sizeSpan +
                            " " +
                            classes.listDescriptionMM
                          }
                        >
                          {c.productSize.s2x12 == null
                            ? ""
                            : c.productSize.s2x12 == 0
                            ? ""
                            : "2x12"}
                          <br />
                          {c.productSize.s2x12 == null
                            ? ""
                            : c.productSize.s2x12 == 0
                            ? ""
                            : c.productSize.s2x12}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s2x14 != 0 ? (
                        <span
                          className={
                            classes.textCenter +
                            " " +
                            classes.sizeSpan +
                            " " +
                            classes.listDescriptionMM
                          }
                        >
                          {c.productSize.s2x14 == null
                            ? ""
                            : c.productSize.s2x14 == 0
                            ? ""
                            : "2x14"}
                          <br />
                          {c.productSize.s2x14 == null
                            ? ""
                            : c.productSize.s2x14 == 0
                            ? ""
                            : c.productSize.s2x14}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s3x0 != 0 ? (
                        <span
                          className={
                            classes.textCenter +
                            " " +
                            classes.sizeSpan +
                            " " +
                            classes.listDescriptionMM
                          }
                        >
                          {c.productSize.s3x0 == null
                            ? ""
                            : c.productSize.s3x0 == 0
                            ? ""
                            : "3x0"}
                          <br />
                          {c.productSize.s3x0 == null
                            ? ""
                            : c.productSize.s3x0 == 0
                            ? ""
                            : c.productSize.s3x0}
                        </span>
                      ) : (
                        ""
                      )}
                    </GridContainer>
                    <GridContainer className={classes.sizePadding}>
                      {c.productSize.s1x9 != 0 ? (
                        <span
                          className={
                            classes.textCenter +
                            " " +
                            classes.sizeSpan +
                            " " +
                            classes.listDescriptionMM
                          }
                        >
                          {c.productSize.s1x9 == null
                            ? ""
                            : c.productSize.s1x9 == 0
                            ? ""
                            : "1x9"}
                          <br />
                          {c.productSize.s1x9 == null
                            ? ""
                            : c.productSize.s1x9 == 0
                            ? ""
                            : c.productSize.s1x9}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s1x11 != 0 ? (
                        <span
                          className={
                            classes.textCenter +
                            " " +
                            classes.sizeSpan +
                            " " +
                            classes.listDescriptionMM
                          }
                        >
                          {c.productSize.s1x11 == null
                            ? ""
                            : c.productSize.s1x11 == 0
                            ? ""
                            : "1x11"}
                          <br />
                          {c.productSize.s1x11 == null
                            ? ""
                            : c.productSize.s1x11 == 0
                            ? ""
                            : c.productSize.s1x11}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s1x13 != 0 ? (
                        <span
                          className={
                            classes.textCenter +
                            " " +
                            classes.sizeSpan +
                            " " +
                            classes.listDescriptionMM
                          }
                        >
                          {c.productSize.s1x13 == null
                            ? ""
                            : c.productSize.s1x13 == 0
                            ? ""
                            : "1x13"}
                          <br />
                          {c.productSize.s1x13 == null
                            ? ""
                            : c.productSize.s1x13 == 0
                            ? ""
                            : c.productSize.s1x13}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s1x15 != 0 ? (
                        <span
                          className={
                            classes.textCenter +
                            " " +
                            classes.sizeSpan +
                            " " +
                            classes.listDescriptionMM
                          }
                        >
                          {c.productSize.s1x15 == null
                            ? ""
                            : c.productSize.s1x15 == 0
                            ? ""
                            : "1x15"}
                          <br />
                          {c.productSize.s1x15 == null
                            ? ""
                            : c.productSize.s1x15 == 0
                            ? ""
                            : c.productSize.s1x15}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s2x1 != 0 ? (
                        <span
                          className={
                            classes.textCenter +
                            " " +
                            classes.sizeSpan +
                            " " +
                            classes.listDescriptionMM
                          }
                        >
                          {c.productSize.s2x1 == null
                            ? ""
                            : c.productSize.s2x1 == 0
                            ? ""
                            : "2x1"}
                          <br />
                          {c.productSize.s2x1 == null
                            ? ""
                            : c.productSize.s2x1 == 0
                            ? ""
                            : c.productSize.s2x1}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s2x3 != 0 ? (
                        <span
                          className={
                            classes.textCenter +
                            " " +
                            classes.sizeSpan +
                            " " +
                            classes.listDescriptionMM
                          }
                        >
                          {c.productSize.s2x3 == null
                            ? ""
                            : c.productSize.s2x3 == 0
                            ? ""
                            : "2x3"}
                          <br />
                          {c.productSize.s2x3 == null
                            ? ""
                            : c.productSize.s2x3 == 0
                            ? ""
                            : c.productSize.s2x3}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s2x5 != 0 ? (
                        <span
                          className={
                            classes.textCenter +
                            " " +
                            classes.sizeSpan +
                            " " +
                            classes.listDescriptionMM
                          }
                        >
                          {c.productSize.s2x5 == null
                            ? ""
                            : c.productSize.s2x5 == 0
                            ? ""
                            : "2x5"}
                          <br />
                          {c.productSize.s2x5 == null
                            ? ""
                            : c.productSize.s2x5 == 0
                            ? ""
                            : c.productSize.s2x5}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s2x7 != 0 ? (
                        <span
                          className={
                            classes.textCenter +
                            " " +
                            classes.sizeSpan +
                            " " +
                            classes.listDescriptionMM
                          }
                        >
                          {c.productSize.s2x7 == null
                            ? ""
                            : c.productSize.s2x7 == 0
                            ? ""
                            : "2x7"}
                          <br />
                          {c.productSize.s2x7 == null
                            ? ""
                            : c.productSize.s2x7 == 0
                            ? ""
                            : c.productSize.s2x7}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s2x9 != 0 ? (
                        <span
                          className={
                            classes.textCenter +
                            " " +
                            classes.sizeSpan +
                            " " +
                            classes.listDescriptionMM
                          }
                        >
                          {c.productSize.s2x9 == null
                            ? ""
                            : c.productSize.s2x9 == 0
                            ? ""
                            : "2x9"}
                          <br />
                          {c.productSize.s2x9 == null
                            ? ""
                            : c.productSize.s2x9 == 0
                            ? ""
                            : c.productSize.s2x9}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s2x11 != 0 ? (
                        <span
                          className={
                            classes.textCenter +
                            " " +
                            classes.sizeSpan +
                            " " +
                            classes.listDescriptionMM
                          }
                        >
                          {c.productSize.s2x11 == null
                            ? ""
                            : c.productSize.s2x11 == 0
                            ? ""
                            : "2x11"}
                          <br />
                          {c.productSize.s2x11 == null
                            ? ""
                            : c.productSize.s2x11 == 0
                            ? ""
                            : c.productSize.s2x11}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s2x13 != 0 ? (
                        <span
                          className={
                            classes.textCenter +
                            " " +
                            classes.sizeSpan +
                            " " +
                            classes.listDescriptionMM
                          }
                        >
                          {c.productSize.s2x13 == null
                            ? ""
                            : c.productSize.s2x13 == 0
                            ? ""
                            : "2x13"}
                          <br />
                          {c.productSize.s2x13 == null
                            ? ""
                            : c.productSize.s2x13 == 0
                            ? ""
                            : c.productSize.s2x13}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s2x15 != 0 ? (
                        <span
                          className={
                            classes.textCenter +
                            " " +
                            classes.sizeSpan +
                            " " +
                            classes.listDescriptionMM
                          }
                        >
                          {c.productSize.s2x15 == null
                            ? ""
                            : c.productSize.s2x15 == 0
                            ? ""
                            : "2x15"}
                          <br />
                          {c.productSize.s2x15 == null
                            ? ""
                            : c.productSize.s2x15 == 0
                            ? ""
                            : c.productSize.s2x15}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s3x1 != 0 ? (
                        <span
                          className={
                            classes.textCenter +
                            " " +
                            classes.sizeSpan +
                            " " +
                            classes.listDescriptionMM
                          }
                        >
                          {c.productSize.s3x1 == null
                            ? ""
                            : c.productSize.s3x1 == 0
                            ? ""
                            : "3x1"}
                          <br />
                          {c.productSize.s3x1 == null
                            ? ""
                            : c.productSize.s3x1 == 0
                            ? ""
                            : c.productSize.s3x1}
                        </span>
                      ) : (
                        ""
                      )}
                    </GridContainer>
                    {/* <span className={classes.listDescription}>
                      {c && c.name ? c.name + ", " : ""}{" "}
                      {c && c.mycomment ? c.mycomment : ""}
                    </span> */}
                  </span>,
                  <span
                    className={classes.headRight + " " + classes.listNumber}
                    key={c.id}
                  >
                    {c.cartQuantity}
                  </span>,
                  <span
                    className={classes.headRight + " " + classes.listNumber}
                    key={c.id}
                  >
                    {c.productSize.grandTotal}
                  </span>,
                  <span
                    className={classes.headRight + " " + classes.listNumber}
                    key={c.id}
                  >
                    ₹{" "}
                    {c.gstCash === true
                      ? c.taxInclude.toFixed(2)
                      : c.cashPrice.toFixed(2)}
                  </span>,
                  <span
                    className={classes.headRight + " " + classes.listNumber}
                    key={c.id}
                  >
                    ₹ {Math.round(c.amount)}
                  </span>,
                  <Tooltip
                    id="close1"
                    title="Cart Remove"
                    placement="left"
                    classes={{ tooltip: classe.tooltip }}
                    key={c.id}
                  >
                    <Button
                      link
                      className={classe.actionButton}
                      onClick={() => handleRemove(c)}
                    >
                      <Close style={{ color: "red" }} />
                    </Button>
                  </Tooltip>,
                ]),
                [
                  "",
                  <span className={classes.listNumber} key={1}>
                    Carton Charges
                    <br />
                    Grand Total
                  </span>,
                  <span className={classes.listNumber} key={2}>
                    <br />
                    Advance - ₹{" "}
                    {Math.round(
                      viewCart.reduce(
                        (a, b) =>
                          a +
                          b.metalGram *
                            b.cartSets.pcs *
                            b.cartBox.box *
                            b.productSize.grandTotal *
                            (b.cartRawPrice.price + b.cartRawPrice.different),
                        0
                      )
                    ) + "/-"}
                    {/* {viewCart.map((c) => {
                      totalCal =
                        totalCal +
                        c.metalGram *
                          c.sets.pcs *
                          c.box.box *
                          c.total *
                          c.metal.price;
                      return c.mixmmList && c.mixmmList
                        ? c.mixmmList.map((m) => {
                            totalCalMix =
                              totalCalMix +
                              m.metalOption.metalgram *
                                m.sets.pcs *
                                m.metal.price *
                                c.box.box *
                                c.total;
                            totalSum = totalCalMix + totalCal;
                            return "";
                          })
                        : "";
                    })}
                    Advance - ₹
                    {Math.round((totalSum = totalCalMix + totalCal)) + "/-"} */}
                  </span>,
                  <span key={3}>
                    <br />
                    <span
                      className={classes.headRight + " " + classes.listNumber}
                    >
                      Carton-
                      {viewCart.reduce(
                        (a, b) => a + b.cartQuantity,
                        0
                      )}
                    </span>
                  </span>,
                  <span key={3}>
                    <br />
                    <span
                      className={classes.headRight + " " + classes.listNumber}
                    >
                      Unit-
                      {viewCart &&
                        viewCart.reduce(
                          (a, b) => a + b.productSize.grandTotal,
                          0
                        )}
                    </span>
                  </span>,
                  "",
                  <span key={4}>
                    <span
                      className={classes.headRight + " " + classes.listNumber}
                    >
                      ₹{" "}
                      {viewCart.reduce((a, b) => a + b.cartPackCharge * b.cartQuantity, 0) +
                        "/-"}
                    </span>
                    <br />
                    <span
                      className={classes.headRight + " " + classes.listNumber}
                    >
                      ₹{" "}
                      {Math.round(
                        viewCart.reduce(
                          (a, b) => a + b.amount + b.cartPackCharge * b.cartQuantity,
                          0
                        )
                      ) + "/-"}
                    </span>
                  </span>,
                  "",
                ],
              ]}
            />
            <hr style={{ borderBottom: "1px solid rgba(0,0,0,.1)" }} />
            <div
              style={{
                float: "right",
                marginTop: "15px",
                marginBottom: "15px",
              }}
            >
              <Button color="rose" round onClick={confirmOrder}>
                Confirm Order
              </Button>{" "}
              &nbsp;&nbsp;
              <Button color="rose" round>
                Pay Now
              </Button>
            </div>
          </div>
        </GridContainer>
      </Card>
    </div>
  );
}
