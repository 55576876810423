export const imgApi = `https://imgstore.bangles.org.in`; 

// export const localApi = `http://localhost:8085/api`;

// export const localApi = `https://inshabangles.com:8443/api`;
// export const localApi = `https://bangles.org.in:8443/api`;
export const localApi = `https://api.inshabangles.com/api`;

export const adminObj = JSON.parse(sessionStorage.getItem("sessionData"));

export const catApi = async () => {
    const api = localApi + `/category/list/byAdminId/${adminObj.id}`;
    const result = await fetch(api);
    const getResult = await result.json();
    return getResult;
}