import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { isValidPhoneNumber } from "react-phone-number-input";
import { localApi } from "utils/imgApi";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from "@material-ui/core/MenuItem";
import EmailIcon from "@material-ui/icons/Email";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Face from "@material-ui/icons/Face";
import BusinessIcon from "@material-ui/icons/Business";
import Check from "@material-ui/icons/Check";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import styles from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import style from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styl from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import { getStates, getCities } from "utils/util";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(style);
const useStyl = makeStyles(styl);

export default function RegisterPage() {
  const [countryList, setCountryList] = React.useState([]);
  const [firstName, setFirstName] = React.useState("");
  const [firstNameErr, setFirstNameErr] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [lastNameErr, setLastNameErr] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [mobileErr, setMobileErr] = React.useState("");
  const [mobileWhatsapp, setMobileWhatsapp] = React.useState("");
  const [company, setCompany] = React.useState("");
  const [companyErr, setCompanyErr] = React.useState("");
  const [countryCode, setCountryCode] = React.useState("in");
  const [countryName, setCountryName] = React.useState("India");
  const [cityErr, setCityErr] = React.useState("");
  const [stateCode, setStateCode] = React.useState("");
  const [stateNameErr, setStateNameErr] = React.useState("");
  const [active, setActive] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [checkboxValue, setCheckboxValue] = React.useState(false);
  const [alertType, alertTypeOps] = useState("");
  const [alertMsg, alertMsgOps] = useState("");
  const [stateSelect, setStateSelect] = React.useState("");
  const [citySelect, setCitySelect] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [emailErr, setEmailErr] = React.useState("");
  const [type, setType] = React.useState("");
  const [admin, setAdmin] = React.useState("");
  const [btn, setBtn] = React.useState(false);

  const classes = useStyles();
  const classe = useStyle();
  const classDrop = useStyl();
  const history = useHistory();

  const currentSession = sessionStorage.getItem("sessionData");
  // if (currentSession) {
  //   history.push("/admin/dashboard");
  // }

  async function fetchCountryListCode() {
    const api = localApi + `/countrylistcode/allList`;
    const result = await fetch(api);
    const getResult = await result.json();
    // console.log(getResult);
    setCountryList(getResult);
  }

  useEffect(() => {
    fetchCountryListCode();
  }, []);

  async function getUser() {
    const api = localApi + `/type/list`;
    const result = await fetch(api);
    const getResult = await result.json();
    // console.log(getResult.filter((item) => item.title !== "Admin"));
    setType(getResult.TypeList);
    // console.log(getResult);
  }

  useEffect(() => {
    getUser();
  }, []);

  async function getAdmin() {
    const api = localApi + `/admin/allList`;
    const result = await fetch(api);
    const getResult = await result.json();
    // console.log(getResult.AdminList);
    setAdmin(getResult.AdminList);
  }

  useEffect(() => {
    getAdmin();
  }, []);

  const handleMobileNumber = (mobile) => {
    setMobile(mobile);
    fetch(localApi + `/register/crm/find/byMobile/${mobile}`)
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log(result);
          if(result.message === "Not Found"){
            setMobileErr("")
          }else{
          setMobileErr(result.message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const handleEmail = (e) => {
    const { value } = e.target;
    setEmail(value);
    fetch(localApi + `/register/crm/find/byEmail/${value}`)
      .then((res) => res.json())
      .then(
        (result) => {
          if(result.message === "Not Found"){
            setEmailErr("")
          }else{
          setEmailErr(result.message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const handleCheckedBox = (e) => {
    // console.log(checkboxValue);
    if (e.target?.checked) {
      setMobileWhatsapp(mobile);
    } else {
      setMobileWhatsapp("");
    }
    setCheckboxValue(e.target.checked);
  };

  const handleState = (event) => {
    setStateSelect(event.target.value);
    setStateNameErr("");
  };

  const handleCity = (event) => {
    setCitySelect(event.target.value);
    setCityErr("");
  };

  const Submit = (event) => {
    const getFind = type.find((f) => f.title === "Crm");
    const getFindAdmin = admin.find((f) => f.websitename === "bangles.org.in");
    const countryListCode = countryList.find(
      (f) => f.countryCode.toLowerCase() === countryCode
    );

    let emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;

    // const data = {
    //   websitename: getFindAdmin.websitename,
    //   typeId: getFind.typeId,
    //   type: getFind.title,
    //   profit: getFind.profit,
    //   firstname: firstName,
    //   lastname: lastName,
    //   mobile: mobile,
    //   whatsapp: mobileWhatsapp,
    //   email: email,
    //   company: company,
    //   city: citySelect.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ""),
    //   state: stateSelect.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ""),
    //   country: countryName.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ""),
    //   smsMarketing: active,
    //   countryCode: countryListCode.countryCode,
    //   isoNumber: countryListCode.isoNumber,
    //   otp: {
    //     otp: 0,
    //     active: 0,
    //   },
    // };

    // console.log(JSON.stringify(data));

    event.preventDefault();
    let errorCount = 0;
    if (firstName === "" || !firstName) {
      errorCount++;
      setFirstNameErr("Please Enter First Name");
    }
    if (lastName === "" || !lastName) {
      errorCount++;
      setLastNameErr("Please Enter Last Name");
    }
    if (mobile === "" || !mobile || !isValidPhoneNumber(`+${mobile}`)) {
      errorCount++;
      setMobileErr("Please Enter Valid Mobile Number");
    }
    if (email === "" || !email || !emailRegex.test(email)) {
      errorCount++;
      setEmailErr("Please Enter Email Id");
    }
    if (company === "" || !company) {
      errorCount++;
      setCompanyErr("Please Enter Company Name");
    }
    if (citySelect === "" || !citySelect) {
      errorCount++;
      setCityErr("Please Select City Name");
    }
    if (stateSelect === "" || !stateSelect) {
      errorCount++;
      setStateNameErr("Please Select State Name");
    }
    if (mobileErr === "Mobile No. Already Registered") {
      errorCount++;
      alertTypeOps("danger");
      alertMsgOps("Number alerady register");
      setSubmitted(true);
    }
    if (emailErr === "Email ID Already Registered") {
      errorCount++;
      alertTypeOps("danger");
      alertMsgOps("Email alerady register");
      setSubmitted(true);
    }
    if (errorCount === 0) {
      setBtn(true);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          websitename: "inshabangles.com",
          typeId: getFind.typeId,
          type: getFind.title,
          profit: getFind.profit,
          firstname: firstName,
          lastname: lastName,
          mobile: mobile,
          whatsapp: mobileWhatsapp,
          email: email,
          company: company,
          city: citySelect.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ""),
          state: stateSelect.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ""),
          country: countryName.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ""),
          smsMarketing: active,
          countryCode: countryListCode.countryCode,
          isoNumber: countryListCode.isoNumber,
          otp: {
            otp: 0,
            active: 0,
          },
        }),
      };

      fetch(
        localApi + `/register/crm/add/byAdminId/${getFindAdmin.id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          if (data.message === "Data Inserted Successfully !!!") {
            alertTypeOps("success");
            alertMsgOps("Succesfully registerd!");
            setSubmitted(true);
          }
        });
    }
  };

  const hideAlert = () => {
    setSubmitted(false);
    // history.push("/auth/login-page");
    // if (mobileErr === "Not Found" && emailErr === "Not Found") {
    //   history.push("/auth/login-page");
    // }
    if (!mobileErr || mobileErr === "") {
      if (!emailErr || emailErr === "") {
        history.push("/auth/login-page");
      }
    }
  };

  const mystyle = {
    paddingLeft: 12,
    flex: 1,
  };

  return (
    <div className={classes.container}>
      <GridContainer justify="center" alignItems="center">
        <GridItem xs={12} sm={10} md={6}>
          <Card className={classes.cardSignup}>
            <h2 className={classes.cardTitle}>Calling Register</h2>
            <CardBody>
              <GridContainer justify="center">
                <GridItem xs={12} sm={10} md={8}>
                  <div className={classes.center}>
                    <Button justIcon round color="twitter">
                      <i className="fab fa-twitter" />
                    </Button>
                    {` `}
                    <Button justIcon round color="dribbble">
                      <i className="fab fa-dribbble" />
                    </Button>
                    {` `}
                    <Button justIcon round color="facebook">
                      <i className="fab fa-facebook-f" />
                    </Button>
                    {` `}
                    <h4 className={classes.socialTitle}>or be classical</h4>
                  </div>

                  <form className={classes.form}>
                    {submitted ? (
                      <SweetAlert
                        type={alertType}
                        style={{ display: "block", marginTop: "-100px" }}
                        title={alertMsg}
                        onConfirm={() => hideAlert()}
                        confirmBtnCssClass={
                          classe.button + " " + classe.success
                        }
                      />
                    ) : null}

                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <Face className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        placeholder: "First Name...",
                      }}
                      id="firstName"
                      name="firstName"
                      value={firstName}
                      onChange={(e) => {
                        setFirstName(e.target.value);
                        setFirstNameErr("");
                      }}
                      required={true}
                    />
                    <span style={{ color: "red", marginLeft: "12px" }}>
                      {firstNameErr}
                    </span>
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <Face className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        placeholder: "Last Name...",
                      }}
                      id="lastName"
                      name="lastName"
                      value={lastName}
                      onChange={(e) => {
                        setLastName(e.target.value);
                        setLastNameErr("");
                      }}
                      required={true}
                    />
                    <span style={{ color: "red", marginLeft: "12px" }}>
                      {lastNameErr}
                    </span>

                    <br />
                    <PhoneInput
                      style={mystyle}
                      inputStyle={{
                        width: "105%",
                        border: "none",
                        borderBottom: "1px solid #040000",
                        borderRadius: "0",
                      }}
                      country={"in"}
                      name="mobile"
                      value={mobile}
                      onChange={(mobile) => {
                        let country_code = document.body.querySelector(
                          "div.flag"
                        )?.className;
                        country_code =
                          country_code && country_code.replace("flag", "");
                        setCountryCode(country_code.trim());
                        // console.log(country_code);
                        handleMobileNumber(mobile);
                        let country_name = document.body.querySelector(
                          "div.selected-flag"
                        )?.title;
                        setCountryName(country_name.slice(0, -6));
                      }}
                      // onChange={(mobile) => {
                      //   setMobile(mobile.length <= 12 ? mobile : mobile);
                      // setMobileErr("");
                      // }}
                      required={true}
                    />
                    <span style={{ color: "red", marginLeft: "12px" }}>
                      {mobileErr}
                    </span>
                    <br />
                    <FormControlLabel
                      classes={{
                        root: classes.checkboxLabelControl,
                        label: classes.checkboxLabel,
                      }}
                      control={
                        <Checkbox
                          // id="active"
                          checked={checkboxValue || false}
                          onChange={(e) => handleCheckedBox(e)}
                          tabIndex={-1}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                        />
                      }
                      label={
                        <span style={{ color: "#9c27b0" }}>
                          If your WhatsApp Number is same, click on this
                          checkbox.
                        </span>
                      }
                    />
                    <br />
                    <PhoneInput
                      style={mystyle}
                      inputStyle={{
                        width: "105%",
                        border: "none",
                        borderBottom: "1px solid #040000 ",
                        borderRadius: "0",
                      }}
                      country={"in"}
                      name="mobile"
                      value={mobileWhatsapp}
                      onChange={(mobileWhatsapp) => {
                        if (mobile === mobileWhatsapp) {
                          setCheckboxValue(true);
                        } else {
                          setCheckboxValue(false);
                        }
                        setMobileWhatsapp(mobileWhatsapp);
                        // setMobileWhatsappErr("");
                      }}
                      // required={true}
                    />
                    {/* <span style={{ color: "red" }}>{mobileWhatsappErr}</span> */}
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <BusinessIcon
                              className={classes.inputAdornmentIcon}
                            />
                          </InputAdornment>
                        ),
                        placeholder: "Company...",
                      }}
                      id="company"
                      name="company"
                      value={company}
                      onChange={(e) => {
                        setCompany(e.target.value);
                        setCompanyErr("");
                      }}
                      required={true}
                    />
                    <span style={{ color: "red", marginLeft: "12px" }}>
                      {companyErr}
                    </span>

                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <EmailIcon className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        placeholder: "Email...",
                      }}
                      id="email"
                      name="email"
                      value={email}
                      onChange={handleEmail}
                      required={true}
                    />
                    <span style={{ color: "red", marginLeft: "12px" }}>
                      {emailErr}
                    </span>

                    <GridItem style={{ width: "110%" }}>
                      <FormControl
                        fullWidth
                        className={classDrop.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classDrop.selectLabel}
                        >
                          Select State...
                        </InputLabel>

                        <Select
                          MenuProps={{
                            className: classDrop.selectMenu,
                          }}
                          classes={{
                            select: classDrop.select,
                          }}
                          value={stateSelect}
                          onChange={handleState}
                          required={true}
                          inputProps={{
                            name: "stateSelect",
                            id: "simple-select",
                          }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: classDrop.selectMenuItem,
                            }}
                          >
                            Select State
                          </MenuItem>
                          {getStates(countryCode).map((item) => (
                            <MenuItem
                              key={item.name}
                              value={item.name}
                              onClick={() => setStateCode(item?.state_code)}
                              classes={{
                                root: classDrop.selectMenuItem,
                                selected: classDrop.selectMenuItemSelected,
                              }}
                            >
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <span style={{ color: "red" }}>{stateNameErr}</span>
                    </GridItem>

                    <GridItem style={{ width: "110%" }}>
                      <FormControl
                        fullWidth
                        className={classDrop.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classDrop.selectLabel}
                        >
                          Select City...
                        </InputLabel>

                        <Select
                          MenuProps={{
                            className: classDrop.selectMenu,
                          }}
                          classes={{
                            select: classDrop.select,
                          }}
                          value={citySelect}
                          onChange={handleCity}
                          required={true}
                          inputProps={{
                            name: "stateSelect",
                            id: "simple-select",
                          }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: classDrop.selectMenuItem,
                            }}
                          >
                            Select City
                          </MenuItem>
                          {getCities(countryCode, stateCode).map((item) => (
                            <MenuItem
                              key={item.name}
                              value={item.name}
                              classes={{
                                root: classDrop.selectMenuItem,
                                selected: classDrop.selectMenuItemSelected,
                              }}
                            >
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <span style={{ color: "red" }}>{cityErr}</span>
                    </GridItem>
                    <br />

                    <FormControlLabel
                      classes={{
                        root: classes.checkboxLabelControl,
                        label: classes.checkboxLabel,
                      }}
                      control={
                        <Checkbox
                          id="active"
                          value={active}
                          onChange={(e) => {
                            setActive(e.target.checked);
                          }}
                          tabIndex={-1}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                        />
                      }
                      label={
                        <span style={{ color: "#9c27b0" }}>
                          I would like to recieve the latest news from the
                          Company by phone/whatsapp
                        </span>
                      }
                    />
                  </form>
                  <div className={classes.center}>
                    {btn === true ? (
                      <Button round>Sign Up</Button>
                    ) : (
                      <Button round color="primary" onClick={Submit}>
                        Sign Up
                      </Button>
                    )}
                  </div>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
