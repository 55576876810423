import React, { useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// import InputLabel from "@material-ui/core/InputLabel";
import axios from "axios";
import { localApi } from "utils/imgApi";
import { AiOutlineFolderView } from "react-icons/ai";
import { IoLogoWhatsapp } from "react-icons/io";
import { ImMobile } from "react-icons/im";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
// import CardAvatar from "components/Card/CardAvatar.js";

import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import style from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(style);

export default function ViewContactDetails(props) {
  // const headApi = `https://api.bangles.org.in/api/rest`;

  const [items, setItems] = React.useState([]);
  const [items1, setItems1] = React.useState([]);
  const [items2, setItems2] = React.useState([]);
  const [simpleSelect, setSimpleSelect] = React.useState("");
  const [simpleSelect1, setSimpleSelect1] = React.useState("");

  const classes = useStyles();
  const classe = useStyle();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  // async function fetchData() {
  //   var tempData = {};
  //   const api =
  //     localApi + `/contact/list/byCrmReferenceIdAndCountryList/${userObj.id}`;
  //   const result = await fetch(api);
  //   const getResult = await result.json();

  //   const countryCount =
  //     getResult &&
  //     getResult.map((p) => {
  //       return p.country;
  //     });
  //   Promise.all(
  //     countryCount.map(async (country) => {
  //       const usedApi = await fetch(
  //         localApi +
  //           `/contact/list/byCrmReferenceIdAndCountry/${userObj.id}/${country}`
  //       );
  //       const data = await usedApi.json();
  //       // console.log(data);
  //       tempData = { ...tempData, [country]: data };
  //     })
  //   ).then(() => {
  //     // console.log(tempData);
  //     const tempItems2 = getResult.map((i) => {
  //       return { ...i, u: tempData[i.country] || 0 };
  //     });
  //     // console.log(tempItems2);
  //     setItems(tempItems2);
  //   });
  // }

  async function fetchData() {
    var tempData = {};
    const api =
      localApi + `/contact/list/byCrmReferenceId/${userObj.id}`;
    const result = await fetch(api);
    const getResult = await result.json();

    const z = getResult.reduce(
      (m, x) => ({ ...m, [x.country]: (m[x.country] || 0) + 1 }),
      {}
    );
    // console.log(z);

    const grouped = [];
    getResult.forEach((it) => {
      const existingCountry = grouped.map((it) => it.country);

      if (existingCountry.includes(it.country)) {
        const existingCountry = grouped.find((ex) => ex.country === it.country);
        // existingCountry.aId += it.aId;
        // existingCountry.rId += it.rId;
      } else {
        grouped.push({
          ...it,
          country: it.country,
        });
      }
    });

    const tempItems2 = grouped.map((i) => {
      return { ...i, u: z[i.country] || 0 };
    });
    // console.log(tempItems2);

    setItems(tempItems2);

  }

  // async function getUsers() {
  //   const api = headApi + `/user/countryList?userid=1`;
  //   const result = await fetch(api);
  //   const getResult = await result.json();
  //   setItems(getResult);
  // }

  useEffect(() => {
    // getUsers();
    fetchData();
  }, []);

  // const handleSimple = async (event) => {
  //   // setSimpleSelect(event.target.value);
  //   const { value } = event.target;
  //   // const item = items.find((item) => item.id == value);
  //   setSimpleSelect(value);
  //   if (value) {
  //     var tempData = {};
  //     const api =
  //       localApi +
  //       `/contact/list/byCrmReferenceIdAndStateList/${userObj.id}/${value}`;
  //     const result = await fetch(api);
  //     const getResult = await result.json();

  //     const stateCount =
  //     getResult &&
  //     getResult.map((p) => {
  //       return p.state;
  //     });

  //     Promise.all(
  //       stateCount.map(async (state) => {
  //         const usedApi = await fetch(
  //           localApi +
  //             `/contact/list/byCrmReferenceIdAndState/${userObj.id}/${state}`
  //         );
  //         const data = await usedApi.json();
  //         // console.log(data);
  //         tempData = { ...tempData, [state]: data };
  //       })
  //     ).then(() => {
  //       // console.log(tempData);
  //       const tempItems2 = getResult.map((i) => {
  //         return { ...i, u: tempData[i.state] || 0 };
  //       });
  //       // console.log(tempItems2);
  //       setItems1(tempItems2);
  //     });
  //   }
  // };

  const handleSimple = async (event) => {
    // setSimpleSelect(event.target.value);
    const { value } = event.target;
    // const item = items.find((item) => item.id == value);
    setSimpleSelect(value);
    if (value) {
      var tempData = {};
      const api =
        localApi +
        `/contact/list/byCrmReferenceIdAndStatesList/${userObj.id}/${value}`;
      const result = await fetch(api);
      const getResult = await result.json();

      // console.log(getResult);

      const z = getResult.reduce(
        (m, x) => ({ ...m, [x.state]: (m[x.state] || 0) + 1 }),
        {}
      );
      // console.log(z);
  
      const grouped = [];
      getResult.forEach((it) => {
        const existingState = grouped.map((it) => it.state);
  
        if (existingState.includes(it.state)) {
          const existingState = grouped.find((ex) => ex.state === it.state);
          // existingState.aId += it.aId;
          // existingState.rId += it.rId;
        } else {
          grouped.push({
            ...it,
            state: it.state,
          });
        }
      });
  
      const tempItems2 = grouped.map((i) => {
        return { ...i, u: z[i.state] || 0 };
      });

      setItems1(tempItems2);

    }
  };

  const handleSimple1 = async (event) => {
    // alert(event.target.value);
    // setSimpleSelect(event.target.value);
    const { value } = event.target;
    // const itemm = items1.find((itemm) => itemm.id == value);
    setSimpleSelect1(value);
    if (value) {
      axios
        .get(
          localApi +
            `/contact/list/byCrmReferenceIdAndAllState/${userObj.id}/${value}`
        )
        .then((res) => setItems2(res.data));
    }
  };

  useEffect(() => {
    // console.log("Value Changed")
    // setSimpleSelect1("");
    setItems2("");
  }, [simpleSelect1]);

  return (
    <div>
      <GridContainer justify="center" alignItems="center">
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <AiOutlineFolderView />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>View Contact Details</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer justify="center" alignItems="center">
                {" "}
                <GridItem xs={12} sm={12} md={12}>
                  <FormControl fullWidth className={classe.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classe.selectLabel}
                    >
                      Select Country...
                    </InputLabel>

                    <Select
                      MenuProps={{
                        className: classe.selectMenu,
                      }}
                      classes={{
                        select: classe.select,
                      }}
                      value={simpleSelect}
                      onChange={handleSimple}
                      inputProps={{
                        name: "simpleSelect",
                        id: "simple-select",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classe.selectMenuItem,
                        }}
                      >
                        Select Country...
                      </MenuItem>
                      {items &&
                        items.map((item) => (
                          <MenuItem
                            key={item.country}
                            value={item.country}
                            classes={{
                              root: classe.selectMenuItem,
                              selected: classe.selectMenuItemSelected,
                            }}
                          >
                            {item.country + " (" + item.u + ")"}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>{" "}
                <GridItem xs={12} sm={12} md={12}>
                  <FormControl fullWidth className={classe.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classe.selectLabel}
                    >
                      Select State...
                    </InputLabel>

                    <Select
                      MenuProps={{
                        className: classe.selectMenu,
                      }}
                      classes={{
                        select: classe.select,
                      }}
                      value={simpleSelect1}
                      onChange={handleSimple1}
                      inputProps={{
                        name: "simpleSelect",
                        id: "simple-select",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classe.selectMenuItem,
                        }}
                      >
                        Select State...
                      </MenuItem>
                      {items1 &&
                        items1.map((item1) => (
                          <MenuItem
                            key={item1.state}
                            value={item1.state}
                            classes={{
                              root: classe.selectMenuItem,
                              selected: classe.selectMenuItemSelected,
                            }}
                          >
                            {item1.state + " (" + item1.u + ")"}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        {items2 &&
          items2.map((Ele) => (
            <GridItem xs={12} sm={6} md={6} key={Ele.mobile}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="rose">{Ele.type}</CardIcon>
                  {/* <h3 className={classes.cardIconTitle}>
                  <small>Select Design Images</small>
                </h3> */}
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={6} sm={6} md={6}>
                      <span>
                        <strong>{Ele.firstname + " " + Ele.lastname}</strong>
                      </span>
                      <br />
                      <span>Company</span>
                      <br />
                      <sapn>Mobile</sapn>
                      <br />
                      <span>WhatsApp</span>
                      <br />
                      <span>City</span>
                      <br />
                      <span>Last Update</span>
                      <br />
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                      <span></span>
                      <br />
                      <span>{Ele.company}</span>
                      <br />
                      <ImMobile />
                      &nbsp;&nbsp;
                      <span>
                        <a href={`tel: ${Ele.mobile}`}>{Ele.mobile}</a>
                      </span>
                      <br />
                      <IoLogoWhatsapp color="green" />
                      &nbsp;&nbsp;
                      <span>
                        <a
                          href={`https://api.whatsapp.com/send/?phone=${Ele.whatsapp}&text=Hello&app_absent=0`}
                        >
                          {Ele.whatsapp}
                        </a>
                      </span>
                      <br />
                      <span>{Ele.city}</span>
                      <br />
                      <span>
                        {Ele.lastDay + " days"}
                      </span> 
                      {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                      <span style={{ color: "#d81b60" }}>{Ele.lastUpdate}</span> */}
                      <br />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          ))}
      </GridContainer>
    </div>
  );
}
