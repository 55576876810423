import React, { useEffect } from "react";
// @material-ui/core components
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import { localApi } from "utils/imgApi";
import { IoLogoWhatsapp } from "react-icons/io";
import { ImMobile } from "react-icons/im";
import Update from "@material-ui/icons/Update";
import LocalOffer from "@material-ui/icons/LocalOffer";
import CardFooter from "components/Card/CardFooter.js";
import Icon from "@material-ui/core/Icon";
import DateRange from "@material-ui/icons/DateRange";
import Store from "@material-ui/icons/Store";
import GroupIcon from "@material-ui/icons/Group";

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";
import Person from "@material-ui/icons/Person";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";
import Remove from "@material-ui/icons/Remove";
import Add from "@material-ui/icons/Add";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import stylesDash from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

import product1 from "assets/img/product1.jpg";
import product2 from "assets/img/product2.jpg";
import product3 from "assets/img/product3.jpg";

const useStyles = makeStyles(styles);
const useStylesDash = makeStyles(stylesDash);

export default function CurrentStatus() {
  const [currentStatus, setCurrentStatus] = React.useState([]);

  const [crmCount, setCrmCount] = React.useState("");
  const [crmUse, setCrmUse] = React.useState("");
  const [crmLeft, setCrmLeft] = React.useState("");
  const [crmLast, setCrmLast] = React.useState("");

  const [callReceiptCount, setCallReceiptCount] = React.useState("");
  const [callReceiptActive, setCallReceiptActive] = React.useState("");
  const [callReceiptAllocation, setCallReceiptAllocation] = React.useState("");
  const [callReceiptClose, setCallReceiptClose] = React.useState("");
  const [callReceiptLast, setCallReceiptLast] = React.useState("");

  const [callAllocationCount, setCallAllocationCount] = React.useState("");
  const [callAllocationActive, setCallAllocationActive] = React.useState("");
  const [callAllowAllocation, setCallAllowAllocation] = React.useState("");
  const [callAllocationClose, setCallAllocationClose] = React.useState("");
  const [callAllocationLast, setCallAllocationLast] = React.useState("");

  const classes = useStyles();
  const classesDash = useStylesDash();
  const history = useHistory();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  async function getCrmUserCount() {
    const api = localApi + `/contact/list/byCrmReferenceAndAdminId/${userObj.id}/${userObj.admin.id}`;
    const result = await fetch(api);
    const getResult = await result.json();
    setCrmLeft(getResult && getResult.filter((f) => f.active === false).length);
    setCrmUse(getResult && getResult.filter((f) => f.active === true).length);
    setCrmCount(getResult && getResult.length);
    setCrmLast(getResult && getResult.sort().reverse().filter((f) => f.active === false)[0]);
  }

  async function getCallReceipt() {
    const api = localApi + `/callreceipt/list/byCrmReferenceAndAdminId/${userObj.id}/${userObj.admin.id}`;
    const result = await fetch(api);
    const getResult = await result.json();
    // console.log(getResult);
    setCallReceiptCount(getResult && getResult.length);
    setCallReceiptActive(getResult && getResult.filter((f) => f.callStatus === "OPEN" && f.finalStatus === false && f.allocation === true).length);
    const countA = getResult && getResult.filter((f) => f.allocation === true && f.finalStatus === true).length;
    const countB = getResult && getResult.filter((f) => f.callStatus === "CLOSED" && f.finalStatus === true).length
    setCallReceiptClose(countA + countB);
    setCallReceiptAllocation(getResult && getResult.filter((f) => f.allocation === false && f.callStatus === "OPEN").length)
    setCallReceiptLast(getResult && getResult.sort().reverse().filter((f) => f.allocation === false)[0]);
  }

  async function getCallAllocation() {
    const api = localApi + `/callallocation/list/byCrmReferenceAndAdminId/${userObj.id}/${userObj.admin.id}`;
    const result = await fetch(api);
    const getResult = await result.json();
    // console.log(getResult);
    // setCallAllocationCount(getResult && getResult.length);
    setCallAllocationActive(getResult && getResult.filter((f) => f.callStatus === "OPEN" && f.allocation === false).length);
    const countA = getResult && getResult.filter((f) => f.allocation === true && f.callStatus === "OPEN").length;
    const countB = getResult && getResult.filter((f) => f.callStatus === "CLOSED" && f.finalStatus === true).length
    setCallAllocationClose(countA + countB);
    setCallAllowAllocation(getResult && getResult.length)
    setCallAllocationLast(getResult && getResult.sort().reverse().filter((f) => f.allocation === false)[0]);
  }

  async function getCrmData() {
    const api =
      localApi + `/callreceipt/list/byCrmReferenceIdBeforeDate/${userObj.id}`;
    const results = await fetch(api);
    const getResult = await results.json();
    // console.log(getResult);
    setCurrentStatus(getResult);
  }

  useEffect(() => {
    getCrmData();
    getCrmUserCount();
    getCallReceipt();
    getCallAllocation();
  }, []);

  const handleChange = (e) => {
    const { name, checked } = e.target;
    if (name === "0") {
      let tempUser = currentStatus.map((user) => {
        return { ...user, isChecked: checked };
      });
      //   console.log(tempUser);
      setCurrentStatus(tempUser);
    } else {
      let tempUser = currentStatus.map((user) =>
        user.id == name ? { ...user, isChecked: checked } : user
      );
      //   console.log(tempUser);
      setCurrentStatus(tempUser);
    }
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={6} md={6} lg={3}>
        <Card>
          <CardHeader color="warning" stats icon>
            <CardIcon color="warning">
              {/* <Icon>content_copy</Icon> */}
              <GroupIcon />
            </CardIcon>

            <p className={classesDash.cardCategory}>Contact{" - " + crmCount}<br />Use{" - " + crmUse}<br />Pending{" - " + crmLeft}</p>
          </CardHeader>
          <CardFooter stats>
            <div className={classesDash.stats}>
              <DateRange />
              <a href="/admin/contactList">Last {crmLast && crmLast.lastDay} Update</a>
            </div>
          </CardFooter>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={6} md={6} lg={3}>
        <Card>
          <CardHeader color="success" stats icon>
            <CardIcon color="success">
              <Store />
            </CardIcon>
            <p className={classesDash.cardCategory}>Ticket{" - " + callReceiptCount}<br />Open{" - " + callReceiptActive}<br />Allocation{" - " + callReceiptAllocation}<br />Closed{" - " + callReceiptClose}</p>
          </CardHeader>
          <CardFooter stats>
            <div className={classesDash.stats}>
              <DateRange />
              <a href="/admin/callListReceipt">Last {callReceiptLast && callReceiptLast.lastDay}</a>
            </div>
          </CardFooter>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={6} md={6} lg={3}>
        <Card>
          <CardHeader color="danger" stats icon>
            <CardIcon color="danger">
              <Icon>info_outline</Icon>
            </CardIcon>
            <p className={classesDash.cardCategory}>Ticket{" - " + callAllocationCount}<br />Open{" - " + callAllocationActive}<br />Allocation{" - " + callAllowAllocation}<br />Closed{" - " + callAllocationClose}</p>
          </CardHeader>
          <CardFooter stats>
            <div className={classesDash.stats}>
              <LocalOffer />
              <a href="/admin/cartPending">Last {callAllocationLast && callAllocationLast.lastDay}</a>
            </div>
          </CardFooter>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={6} md={6} lg={3}>
        <Card>
          <CardHeader color="info" stats icon>
            <CardIcon color="info">
              <i className="fab fa-twitter" />
            </CardIcon>
            <p className={classesDash.cardCategory}>Followers</p>
            {/* <h4 className={classesDash.cardTitle}>{follwerCount}</h4> */}
          </CardHeader>
          <CardFooter stats>
            <div className={classesDash.stats}>
              <Update />
              <a href="/admin/follower">Just Updated</a>
            </div>
          </CardFooter>
        </Card>
      </GridItem>
      {currentStatus.length != "0" ? <>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Current Status</h4>
          </CardHeader>
          <CardBody>
            <Table
              striped
              tableHead={[
                "SI No.",
                <Checkbox
                  key="key"
                  className={classes.positionAbsolute}
                  tabIndex={-1}
                  name="0"
                  checked={
                    !currentStatus.some((user) => user?.isChecked !== true)
                  }
                  onChange={handleChange}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />,
                "Date Time",
                "Contact",
                "Call",
                "Sub call",
                "Action",
                "A Date",
                "A Time",
              ]}
              tableData={[
                ...currentStatus.map((c, index) => [
                  <span>{index + 1}</span>,
                  <span>
                    <Checkbox
                      key="key"
                      className={classes.positionAbsolute}
                      tabIndex={-1}
                      name={c.id}
                      checked={c?.isChecked || false}
                      onChange={handleChange}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                    />
                  </span>,
                  <span>{c.currentdate}</span>,
                  <span>
                    {c && c.company
                      ? c.company
                      : c.firstname + " " + c.lastname}{" "}
                    <br />
                    <ImMobile /> {c.mobile}
                  </span>,
                  <span>{c.callStatus}</span>,
                  <span>{c.callSubStatus}</span>,
                  <span>{c.action}</span>,
                  <span>{c.afterdate}</span>,
                  <span>{c.aftertime}</span>,
                ]),
              ]}
            />
          </CardBody>
        </Card>
      </GridItem>
      <div>
        <Button
          color="rose"
          round
          onClick={() =>
            history.push({
              pathname: `/view-calling-print`,
              state: currentStatus.filter((f) => f.isChecked === true),
            })
          }
        >
          print
        </Button>
      </div> </> : "" }
    </GridContainer>
  );
}
