import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { isValidPhoneNumber } from "react-phone-number-input";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import SweetAlert from "react-bootstrap-sweetalert";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { localApi } from "utils/imgApi";
import EmailIcon from "@material-ui/icons/Email";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import BusinessIcon from "@material-ui/icons/Business";
import Face from "@material-ui/icons/Face";
import { HiOutlineUserAdd } from "react-icons/hi";
import sty from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import styles from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import style from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styl from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import { getStates, getCities } from "utils/util";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(style);
const useSty = makeStyles(sty);
const useStys = makeStyles(styl);

export default function AddContact() {
  // const headApi = `https://api.bangles.org.in/api/rest`;
  const [countryList, setCountryList] = React.useState([]);
  const [typeItem, setTypeItem] = React.useState([]);
  const [firstName, setFirstName] = React.useState("");
  const [firstNameErr, setFirstNameErr] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [lastNameErr, setLastNameErr] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [mobileErr, setMobileErr] = React.useState("");
  const [mobileWhatsapp, setMobileWhatsapp] = React.useState("");
  const [company, setCompany] = React.useState("");
  const [companyErr, setCompanyErr] = React.useState("");
  const [cityErr, setCityErr] = React.useState("");
  const [stateCode, setStateCode] = React.useState("");
  const [stateNameErr, setStateNameErr] = React.useState("");
  const [countryCode, setCountryCode] = React.useState("in");
  const [countryName, setCountryName] = React.useState("India");
  const [active, setActive] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [checkboxValue, setCheckboxValue] = React.useState(false);
  const [alertType, alertTypeOps] = useState("");
  const [alertMsg, alertMsgOps] = useState("");
  const [type, setType] = React.useState("");
  const [typeErr, setTypeErr] = React.useState("");
  const [alert, setAlert] = React.useState(false);
  const [stateSelect, setStateSelect] = React.useState("");
  const [citySelect, setCitySelect] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [emailErr, setEmailErr] = React.useState("");
  const [url, setUrl] = React.useState("");
  const [btn, setBtn] = React.useState(false);

  const classes = useStyles();
  const classe = useStyle();
  const classes1 = useSty();
  const classDrop = useStys();
  const history = useHistory();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  async function getType() {
    const api = localApi + `/type/list`;
    const result = await fetch(api);
    const getResult = await result.json();
    setTypeItem(
      getResult.TypeList.filter(
        (item) =>
          item.title !== "Control" &&
          item.title !== "Admin" &&
          item.title !== "Crm"
      )
    );
  }

  useEffect(() => {
    getType();
  }, []);

  async function fetchCountryListCode() {
    const api = localApi + `/countrylistcode/allList`;
    const result = await fetch(api);
    const getResult = await result.json();
    // console.log(getResult);
    setCountryList(getResult);
  }

  useEffect(() => {
    fetchCountryListCode();
  }, []);

  const handleMobileNumber = (mobile) => {
    setMobile(mobile);
    fetch(localApi + `/contact/find/byMobile/${mobile}`)
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log(result);
          // setMobileErr(result.message);
          if (result.message === "Not Found") {
            setMobileErr("")
          } else {
            setMobileErr(result.message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const handleEmail = (e) => {
    const { value } = e.target;
    setEmail(value);
    fetch(localApi + `/contact/find/byEmail/${value}`)
      .then((res) => res.json())
      .then(
        (result) => {
          // setEmailErr(result.message);
          if (result.message === "Not Found") {
            setEmailErr("")
          } else {
            setEmailErr(result.message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const handleCheckedBox = (e) => {
    // console.log(checkboxValue);
    if (e.target?.checked) {
      setMobileWhatsapp(mobile);
    } else {
      setMobileWhatsapp("");
    }
    setCheckboxValue(e.target.checked);
  };

  const handleSimpleType = (event) => {
    setType(event.target.value);
    setTypeErr("");
  };

  const handleState = (event) => {
    setStateSelect(event.target.value);
    setStateNameErr("");
  };

  const handleCity = (event) => {
    setCitySelect(event.target.value);
    setCityErr("");
  };

  const Submit = (event) => {
    const getFind = typeItem.find((f) => f.title === type);
    const countryListCode = countryList.find(
      (f) => f.countryCode.toLowerCase() === countryCode
    );

    let emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;

    // console.log(countryListCode);
    // console.log(userObj.admin.id);
    event.preventDefault();
    let errorCount = 0;
    if (firstName === "" || !firstName) {
      errorCount++;
      setFirstNameErr("Please Enter First Name");
    }
    if (lastName === "" || !lastName) {
      errorCount++;
      setLastNameErr("Please Enter Last Name");
    }
    // if (!mobile || mobile.length < 12) {
    //   errorCount++;
    //   setMobileErr("Please Enter Valid Mobile Number");
    // }
    if (type === "" || !type) {
      errorCount++;
      setTypeErr("Please Select Type Name");
    }
    if (company === "" || !company) {
      errorCount++;
      setCompanyErr("Please Enter Company Name");
    }
    if (citySelect === "" || !citySelect) {
      errorCount++;
      setCityErr("Please Enter City Name");
    }
    if (stateSelect === "" || !stateSelect) {
      errorCount++;
      setStateNameErr("Please Enter State Name");
    }
    if (mobileErr === "Mobile No. Already Registered") {
      errorCount++;
      alertTypeOps("danger");
      alertMsgOps("Number alerady register");
      setSubmitted(true);
    }
    if (emailErr === "Email ID Already Registered") {
      errorCount++;
      alertTypeOps("danger");
      alertMsgOps("Email alerady register");
      setSubmitted(true);
    }
    if (mobile === "" || !mobile || !isValidPhoneNumber(`+${mobile}`)) {
      errorCount++;
      setMobileErr("Please Enter Valid Mobile Number");
    }
    if (email === "" || !email || !emailRegex.test(email)) {
      errorCount++;
      setEmailErr("Please Enter Email Id");
    }
    if (errorCount === 0) {
      setBtn(true);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          websitename: userObj.websitename,
          typeId: getFind.typeId,
          type: getFind.title,
          profit: getFind.profit,
          firstname: firstName,
          lastname: lastName,
          mobile: mobile,
          whatsapp: mobileWhatsapp,
          email: email,
          company: company,
          city: citySelect.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ""),
          state: stateSelect.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ""),
          country: countryName.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ""),
          smsMarketing: active,
          countryCode: countryListCode.countryCode,
          isoNumber: countryListCode.isoNumber,
          url: url,
          regAdminId: userObj.admin.id,
        }),
      };

      fetch(
        localApi + `/contact/add/byCrmReferenceId/${userObj.id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          if (data.message === "Data Inserted Successfully !!!") {
            alertTypeOps("success");
            alertMsgOps("Succesfully !!!");
            setSubmitted(true);
          }
        });
    }
  };

  const hideAlert = () => {
    setSubmitted(false);
    setAlert(false);
    // window.location.reload(false);
    // if (mobileErr === "Not Found" && emailErr === "Not Found") {
    //   window.location.reload(false);
    //   //on success handle code
    //   // history.push("/auth/login-page");
    // }
    if (!mobileErr || mobileErr === "") {
      if (!emailErr || emailErr === "") {
        window.location.reload(false);
      }
    }
  };

  const hideAlertPop = () => {
    setAlert(false);
  };

  const mystyle = {
    paddingLeft: 12,
    flex: 1,
    marginLeft: 15,
  };

  return (
    <div>
      <GridContainer justify="center" alignItems="center">
        {alert ? (
          <SweetAlert
            style={{ display: "block", marginTop: "-100px" }}
            title="Client Not Found!"
            onConfirm={() => hideAlertPop()}
            showConfirm={false}
          >
            Please Add this Client.
          </SweetAlert>
        ) : null}
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <HiOutlineUserAdd />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Add Contact</small>
              </h3>
            </CardHeader>
            {/* <h2 className={classes.cardTitle} style={{textAlign:"center"}}>Register</h2> */}
            <CardBody>
              <GridContainer justify="center" alignItems="center">
                {" "}
                <form className={classes.form}>
                  {submitted ? (
                    <SweetAlert
                      type={alertType}
                      style={{ display: "block", marginTop: "-100px" }}
                      title={alertMsg}
                      onConfirm={() => hideAlert()}
                      confirmBtnCssClass={classe.button + " " + classe.success}
                    >
                      Added Register
                    </SweetAlert>
                  ) : null}
                  <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    style={{ marginLeft: "15px" }}
                  >
                    <FormControl
                      fullWidth
                      className={classDrop.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classDrop.selectLabel}
                      >
                        Select Type
                      </InputLabel>

                      <Select
                        MenuProps={{
                          className: classDrop.selectMenu,
                        }}
                        classes={{
                          select: classDrop.select,
                        }}
                        value={type}
                        onChange={handleSimpleType}
                        required={true}
                        inputProps={{
                          name: "simpleSelect",
                          id: "simple-select",
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classDrop.selectMenuItem,
                          }}
                        >
                          Select Type
                        </MenuItem>
                        {typeItem &&
                          typeItem.map((item) => (
                            <MenuItem
                              key={item.title}
                              value={item.title}
                              classes={{
                                root: classDrop.selectMenuItem,
                                selected: classDrop.selectMenuItemSelected,
                              }}
                            >
                              {item.title}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    <span style={{ color: "red" }}>{typeErr}</span>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <Face className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        placeholder: "First Name...",
                      }}
                      id="firstName"
                      name="firstName"
                      value={firstName}
                      onChange={(e) => {
                        setFirstName(e.target.value);
                        setFirstNameErr("");
                      }}
                      required={true}
                    />
                    <span style={{ color: "red", marginLeft: "12px" }}>
                      {firstNameErr}
                    </span>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <Face className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        placeholder: "Last Name...",
                      }}
                      id="lastName"
                      name="lastName"
                      value={lastName}
                      onChange={(e) => {
                        setLastName(e.target.value);
                        setLastNameErr("");
                      }}
                      required={true}
                    />
                    <span style={{ color: "red", marginLeft: "12px" }}>
                      {lastNameErr}
                    </span>
                  </GridItem>
                  <br />
                  {/* <GridItem xs={12} sm={12} md={12}> */}
                  <PhoneInput
                    style={mystyle}
                    inputStyle={{
                      width: "97%",
                      border: "none",
                      borderBottom: "1px solid #040000 ",
                      borderRadius: "0",
                    }}
                    country={"in"}
                    name="mobile"
                    value={mobile}
                    onChange={(mobile) => {
                      handleMobileNumber(mobile);
                      let country_code = document.body.querySelector("div.flag")
                        ?.className;
                      country_code =
                        country_code && country_code.replace("flag", "");
                      setCountryCode(country_code.trim());
                      let country_name = document.body.querySelector(
                        "div.selected-flag"
                      )?.title;
                      setCountryName(country_name.slice(0, -6));
                    }}
                    required={true}
                  // disabled={true}
                  />
                  <span style={{ color: "red", marginLeft: "27px" }}>
                    {mobileErr}
                  </span>
                  {/* </GridItem> */}

                  <br />
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControlLabel
                      classes={{
                        root: classes.checkboxLabelControl,
                        label: classes.checkboxLabel,
                      }}
                      control={
                        <Checkbox
                          // id="active"
                          checked={checkboxValue || false}
                          onChange={(e) => handleCheckedBox(e)}
                          tabIndex={-1}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                        />
                      }
                      label={
                        <span style={{ color: "#e91e63" }}>
                          If your WhatsApp Number is same, click on this
                          checkbox.
                        </span>
                      }
                    />
                  </GridItem>
                  <br />
                  {/* <GridItem xs={12} sm={12} md={12}> */}
                  <PhoneInput
                    style={mystyle}
                    inputStyle={{
                      width: "97%",
                      border: "none",
                      borderBottom: "1px solid #040000 ",
                      borderRadius: "0",
                    }}
                    country={"in"}
                    name="mobile"
                    value={mobileWhatsapp}
                    onChange={(mobileWhatsapp) => {
                      if (mobile === mobileWhatsapp) {
                        setCheckboxValue(true);
                      } else {
                        setCheckboxValue(false);
                      }
                      setMobileWhatsapp(mobileWhatsapp);
                      // setMobileWhatsappErr("");
                    }}
                  />
                  <br />
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <EmailIcon className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        placeholder: "Email...",
                      }}
                      id="email"
                      name="email"
                      value={email}
                      onChange={handleEmail}
                      required={true}
                    />
                    <span style={{ color: "red", marginLeft: "12px" }}>
                      {emailErr}
                    </span>
                  </GridItem>
                  <br />
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <BusinessIcon
                              className={classes.inputAdornmentIcon}
                            />
                          </InputAdornment>
                        ),
                        placeholder: "Company...",
                      }}
                      id="company"
                      name="company"
                      value={company}
                      onChange={(e) => {
                        setCompany(e.target.value);
                        setCompanyErr("");
                      }}
                      required={true}
                    />
                    <span style={{ color: "red", marginLeft: "12px" }}>
                      {companyErr}
                    </span>
                  </GridItem>

                  <GridItem style={{ marginLeft: "15px" }}>
                    <FormControl
                      fullWidth
                      className={classDrop.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classDrop.selectLabel}
                      >
                        Select State...
                      </InputLabel>

                      <Select
                        MenuProps={{
                          className: classDrop.selectMenu,
                        }}
                        classes={{
                          select: classDrop.select,
                        }}
                        value={stateSelect}
                        onChange={handleState}
                        required={true}
                        inputProps={{
                          name: "stateSelect",
                          id: "simple-select",
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classDrop.selectMenuItem,
                          }}
                        >
                          Select State
                        </MenuItem>
                        {getStates(countryCode).map((item) => (
                          <MenuItem
                            key={item.name}
                            value={item.name}
                            onClick={() => setStateCode(item?.state_code)}
                            classes={{
                              root: classDrop.selectMenuItem,
                              selected: classDrop.selectMenuItemSelected,
                            }}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <span style={{ color: "red" }}>{stateNameErr}</span>
                  </GridItem>

                  <GridItem style={{ marginLeft: "15px" }}>
                    <FormControl
                      fullWidth
                      className={classDrop.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classDrop.selectLabel}
                      >
                        Select City...
                      </InputLabel>

                      <Select
                        MenuProps={{
                          className: classDrop.selectMenu,
                        }}
                        classes={{
                          select: classDrop.select,
                        }}
                        value={citySelect}
                        onChange={handleCity}
                        required={true}
                        inputProps={{
                          name: "stateSelect",
                          id: "simple-select",
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classDrop.selectMenuItem,
                          }}
                        >
                          Select City
                        </MenuItem>
                        {getCities(countryCode, stateCode).map((item) => (
                          <MenuItem
                            key={item.name}
                            value={item.name}
                            classes={{
                              root: classDrop.selectMenuItem,
                              selected: classDrop.selectMenuItemSelected,
                            }}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <span style={{ color: "red" }}>{cityErr}</span>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText="Url..."
                      id="url"
                      inputProps={{
                        placeholder: "Url...",
                        multiline: true,
                        rows: 5,
                      }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      name="url"
                      value={url}
                      onChange={(e) => {
                        setUrl(e.target.value);
                      }}
                    />
                  </GridItem>
                  <br />
                  {/* <GridItem xs={12} sm={12} md={12}>
                    <FormControlLabel
                      classes={{
                        root: classes.checkboxLabelControl,
                        label: classes.checkboxLabel,
                      }}
                      control={
                        <Checkbox
                          id="active"
                          value={active}
                          onChange={(e) => {
                            setActive(e.target.checked);
                          }}
                          tabIndex={-1}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                        />
                      }
                      label={
                        <span style={{ color: "#e91e63" }}>
                          I would like to recieve the latest news from the
                          Company by phone/whatsapp
                        </span>
                      }
                    />
                  </GridItem> */}
                </form>
              </GridContainer>
              {btn === true ? (
                <Button
                  style={{ marginLeft: "200px" }}
                  className={classes1.updateProfileButton}
                >
                  Save
                </Button>
              ) : (
                <Button
                  style={{ marginLeft: "200px" }}
                  color="rose"
                  onClick={Submit}
                  className={classes1.updateProfileButton}
                >
                  Save
                </Button>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
